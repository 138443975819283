import PropTypes from 'prop-types';
import styles from './BannerHeader.module.scss';
import { isMobile } from 'modules';
import { useEffect, useState } from 'react';

const BannerHeader = ({ datosBannerHeader }) => {
	const [mobile, setMobile] = useState(true)
	const { textPrincipal, textPrincipalBold, textSecundario, textoBoton, img, link, background } = datosBannerHeader ?? {};

	useEffect(() => {
		setMobile(isMobile());
	}, []);

	if (datosBannerHeader === null || datosBannerHeader === undefined || textPrincipal === undefined)
		return null;
	const estilosBanner = !img ? {} : (mobile ? { background: `${img ? `url(${img.srcMobile})` : img.background}, ${img ? img.background : "unset"}` }
		: { background: `${img ? `url(${img.src})` : img.background}, ${img ? img.background : "unset"}` })
	return <>
		<div className={styles.contenedorHuincha} style={estilosBanner} >
			<a href={link}>
				<div className={styles.contenedorInfoHuincha}>
					<div className={styles.contenedorTexto}>
						{textPrincipal !== undefined && <p>{textPrincipal}{textPrincipalBold !== undefined && <strong> {textPrincipalBold}</strong>} </p>}
						{textSecundario !== undefined && <p>{textSecundario}</p>}
					</div>
					{textoBoton && <div className={`btn ${styles.btnHuincha}`}>{textoBoton}</div>}
				</div>
			</a>
		</div>
	</>;
};

BannerHeader.propTypes = {
	textPrincipal: PropTypes.string,
	textPrincipalBold: PropTypes.string,
	textSecundario: PropTypes.string,
	textoBoton: PropTypes.string,
	link: PropTypes.string,
	img: PropTypes.shape({
		src: PropTypes.string,
		alt: PropTypes.string,
		srcMobile: PropTypes.string,
		background: PropTypes.string
	}),
};

export { BannerHeader };