import ACLToctoc from './acl'
import { loadSimpleWithSessionHeaderStaticProps } from '@toctoc/design-system'
import { getJsonSchemaRole } from './jsonSchemaRole'
import { ePerfilRole } from './enums';

export const filterMenuOptionsUtil = (headerStaticProps: any, role: string) => {
	const acl = new ACLToctoc(getJsonSchemaRole())
	const decodeRol = atob(role)
	return {
		...headerStaticProps,
		userMenu: acl.filterMenuOptions<Awaited<ReturnType<typeof loadSimpleWithSessionHeaderStaticProps>>["userMenu"][0]>({
			role: decodeRol ? decodeRol : "", userMenu: headerStaticProps.userMenu
		})
	}
}

const isAnyBrokerRole = (roleB64: string = ""): boolean => {	
	const code = roleB64 ? getPerfilByRol(atob(roleB64)) : "";
	return [
		ePerfilRole.clienteCorredor + "",
		ePerfilRole.clienteCorredora + "",
		ePerfilRole.clienteCorredoraIntegrada + "",
		ePerfilRole.clienteCorredorMatriz + "",
		ePerfilRole.PublicadorIntegrado + ""
	].includes(code);
};

const getPerfilByRol = (role: string) => {
	const acl = new ACLToctoc()
	try{
		return acl.getProfileCode(role);
	}catch(error){
		return "-1";
	}
}