/**
 * Extract every member of an object that matches the data-* pattern
 * @param props Any Object
 * @returns An object with a collection of data attributes
 */
export const getDataAttributes = (props: any) => {
	const data = {};
	try {
		for (const p in props) {
			if (p.includes(`data-`) && Object.hasOwnProperty.call(props, p)) {
				data[p] = props[p];
			}
		}
	} catch (ex) {
		console.warn("This should not happend, whatch your input data")
	}
	return data;
};


export const tryParseJSON = (inputString: any) => {
	if (inputString === undefined || inputString == null)
		return null;
	if (inputString.indexOf("{") === -1)
		return null
	try {
		return JSON.parse(inputString);
	}
	catch (ex) {
		console.warn(`Unable to parse "${inputString}", err: `, ex.message);
		return null;
	}
}