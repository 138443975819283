export const IcoSonrisa = ({ w = 40, h = 40, fill = "", stroke = '#3200C1', className = '' }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg"
			width={w}
			height={h}
			viewBox="0 0 40 40"
			className={className}
			fill="none">
			<path d="M20 36.6667C29.2047 36.6667 36.6667 29.2048 36.6667 20C36.6667 10.7953 29.2047 3.33337 20 3.33337C10.7953 3.33337 3.33333 10.7953 3.33333 20C3.33333 29.2048 10.7953 36.6667 20 36.6667Z" stroke={stroke} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M13.3333 23.3334C13.3333 23.3334 15.8333 26.6667 20 26.6667C24.1667 26.6667 26.6667 23.3334 26.6667 23.3334" stroke={stroke} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M15 15H15.0167" stroke={stroke} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M25 15H25.0167" stroke={stroke} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
		</svg>
	);
};