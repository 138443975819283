/* eslint-disable */
import React, { Fragment, useState, useEffect } from 'react';
import RRSS from '../../rrss/view';
import styles from './Footer.module.scss'
import useWindowSize from '../../../public/utils/useWindowsSize';
import { IcoDown } from '../../../iconos';

const origenFooter = "footer"; 

const Footer = (props) => {
	const anchoAlto = useWindowSize()
	const ancho = anchoAlto?.width ?? 576;
	const urlBase = `${props.urlBase}/` ?? 'https://www.toctoc.com/';
	const { footerData: data } = props;
	const { id: userId } = props.user ?? {};

	const renderMenu = items => {
		return <ul>
			{items.map((item, i) => {

				let origen = "?o=" + origenFooter + (item.origen !== undefined ? "_" + item.origen : "");

				return <li key={"lnk" + i}>
					<a
						href={item.target == "_blank" ? item.link : urlBase + item.link + origen}
						target={item.target}
					>{item.title}
					</a>
					{item.menu && renderMenu(item.menu)}
				</li>
			})}
		</ul>
	}

	const renderDetails = items => {
		return items.map((item, i) => {
			return <details key={"detail" + i} className='col-md-3' open={ancho > 576} >
				<summary className={styles.ttpie}>{item.title} <IcoDown /></summary>
				{item.menu && renderMenu(item.menu)}
			</details>
		})

	}

	const ElMEnu = ({ data }) => {
		return renderDetails(data.menu)
	}

	return (
		<>
			<a className={styles.toTop} href="#"><IcoDown /> Subir</a>
			{data.menu !== undefined ?
				<footer className={styles.pie}>
					<div className='container'>
						<div className="row">
							<div className="col-12">
								<a href={urlBase} title='ir a inicio' className={styles.cLogo}>
									<img className={styles.logoH} src="https://d2jd36q67phkec.cloudfront.net/toctoc/img/logos/brand/logo-tt-h-blanco.svg" alt="logo" />
									<img className={styles.logoV} src="https://d2jd36q67phkec.cloudfront.net/toctoc/img/logos/brand/logo-tt-v-blanco.svg" alt="logo" />
								</a>
							</div>
							<div className={`col-sm-6 col-md-4 ${styles.cRrss}`}>
								<h4 className={styles.ttpie}>Síguenos</h4>
								<RRSS className={styles.rrss} />

								<a href={data.contacto.link} className={styles.contacto}>{data.contacto.text}</a>

								{ancho > 576 &&
									<div className={`col-12 ${styles.cSello}`}>
										<a href={data.selloCCS.link} target={'_blank'}>
											<img className={styles.logoCc} src={data.selloCCS.logo} alt={data.selloCCS.alt} />
										</a>
									</div>
								}

							</div>

							<div className="col-sm-6 col-md-8">
								<div className="row">
									<ElMEnu data={data} />
								</div>
							</div>
							{ancho <= 576 &&
								<div className="col-12">
									<a href={data.selloCCS.link} target={'_blank'}>
										<img className={styles.logoCc} src={data.selloCCS.logo} alt={data.selloCCS.alt} />
									</a>
								</div>
							}
						</div>
						<div className={styles.cc}><span>TOCTOC SpA. © 2022</span> Reservados todos los derechos</div>
					</div>
				</footer>
				:
				<div>Cargando opciones...</div>
			}
		</>
	);
};

export default Footer;
