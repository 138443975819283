import { ITarget } from "./types/targetFormInformacionComercial";

export const validateForm = (target: ITarget): object => {
	const { type, id, value } = target;
	let regex;
	const inputAlphaNumeric = ['empresa', 'otroMotivo'];

	switch (type) {
		case "text":
			regex = /^[a-zA-Z ]+$/;
			if(inputAlphaNumeric.includes(id)) regex = /^[a-zA-Z0-9 ]+$/;
      return {
        [id]: !regex.test(String(value).toLowerCase()),
      };
    case "email":
      regex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
      return {
        [id]: !regex.test(String(value).toLowerCase()),
      };
    case "number":
      regex = /^[0-9]+$/;
      return {
				[id]: !regex.test(Number(value) || value === ""),
      };
    case "tel":
      regex = /^[0-9]{0,8}$/;
      return {
        [id]: !regex.test(Number(value)),
      };
  }
};
