export const IcoEdificio = ({ w = 64, h = 64, color = '#3200C1' }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={w} height={h} viewBox="0 0 64 64" fill="none">
			<path d="M4 54H60" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M36 54V10C36 9.46957 35.7893 8.96086 35.4142 8.58579C35.0391 8.21071 34.5304 8 34 8H10C9.46957 8 8.96086 8.21071 8.58579 8.58579C8.21071 8.96086 8 9.46957 8 10V54" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M56 54V26C56 25.4696 55.7893 24.9609 55.4142 24.5858C55.0391 24.2107 54.5304 24 54 24H36" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M16 18H24" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M20 34H28" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M16 44H24" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M44 44H48" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M44 34H48" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
		</svg>
	);
};