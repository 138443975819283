export const IcoAyuda = ({ w = 24, h = 24, fill = "#3200C1", color = 'white', clase }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg"
			width={w}
			height={h}
			viewBox="0 0 24 24"
			fill="none"
			className={clase}>
			<path d="M12.9843 17.9999C12.9843 18.5522 12.5366 18.9999 11.9843 18.9999C11.4321 18.9999 10.9844 18.5522 10.9844 17.9999C10.9844 17.4477 11.4321 17 11.9843 17C12.5366 17 12.9843 17.4477 12.9843 17.9999Z" fill={fill} />
			<path d="M11.9844 14.7501C11.5704 14.7501 11.2344 14.4141 11.2344 14.0001V12.9901C11.2344 12.0381 11.8385 11.1851 12.7365 10.8681C13.9314 10.4471 14.7345 9.17401 14.7345 8.25006C14.7345 6.73303 13.5015 5.5 11.9844 5.5C10.4674 5.5 9.23438 6.73303 9.23438 8.25006C9.23438 8.66406 8.89838 9.00006 8.48438 9.00006C8.07037 9.00006 7.73438 8.66406 7.73438 8.25006C7.73438 5.90704 9.6405 4 11.9844 4C14.3284 4 16.2345 5.90704 16.2345 8.25006C16.2345 9.91815 14.9174 11.6891 13.2354 12.2831C12.9355 12.3881 12.7344 12.673 12.7344 12.991V14.0001C12.7344 14.4141 12.3984 14.7501 11.9844 14.7501Z" fill={fill} />
			<path d="M12 24C5.38293 24 0 18.6171 0 12C0 5.38293 5.38293 0 12 0C18.6171 0 24 5.38293 24 12C24 18.6171 18.6171 24 12 24ZM12 1.5C6.21002 1.5 1.5 6.21002 1.5 12C1.5 17.79 6.21002 22.5 12 22.5C17.79 22.5 22.5 17.79 22.5 12C22.5 6.21002 17.79 1.5 12 1.5Z" fill={fill} />
		</svg>
	);
};

export default IcoAyuda;