export const IcoBaño = ({ w = 19, h = 20, fill = "", color = '#3200C1' }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg"
			width="13"
			height="12"
			viewBox="0 0 13 12"
			fill="none">
			<path d="M12.7548 6.98263C12.7548 6.78479 12.5935 6.62439 12.3947 6.62439H11.6739V1.74C11.6739 0.780564 10.8892 0 9.92468 0C8.96018 0 8.1755 0.780564 8.1755 1.74V2.39307C8.1755 2.5909 8.33675 2.75131 8.53563 2.75131C8.73451 2.75131 8.89577 2.5909 8.89577 2.39307V1.74C8.89577 1.17563 9.35733 0.716486 9.92468 0.716486C10.492 0.716486 10.9536 1.17563 10.9536 1.74V6.62436H1.35389C1.15501 6.62436 0.993759 6.78477 0.993759 6.98261C0.993759 8.59942 2.02176 9.98172 3.46165 10.5172C3.18467 10.8068 3.01336 11.204 3.01336 11.6418C3.01336 11.8396 3.17461 12 3.37349 12C3.57237 12 3.73362 11.8396 3.73362 11.6418C3.73362 11.1526 4.10883 10.7546 4.57007 10.7546C4.58701 10.7546 9.16156 10.7546 9.1785 10.7546C9.63973 10.7546 10.015 11.1526 10.015 11.6418C10.015 11.8396 10.1762 12 10.3751 12C10.574 12 10.7352 11.8396 10.7352 11.6418C10.7352 11.204 10.5639 10.8068 10.2869 10.5172C11.7268 9.98175 12.7548 8.59942 12.7548 6.98263ZM8.96287 10.0382H4.7857C3.21374 10.0382 1.91382 8.85756 1.73502 7.34087H12.0135C11.8347 8.85756 10.5348 10.0382 8.96287 10.0382Z" fill="#343A40" />
			<path d="M8.53566 3.37317C8.33678 3.37317 8.17553 3.53358 8.17553 3.73141V4.04189C8.17553 4.23973 8.33678 4.40013 8.53566 4.40013C8.73454 4.40013 8.8958 4.23973 8.8958 4.04189V3.73141C8.89577 3.53355 8.73454 3.37317 8.53566 3.37317Z" fill="#343A40" />
			<path d="M8.53563 5.09241C8.33675 5.09241 8.1755 5.25281 8.1755 5.45065V5.76113C8.1755 5.95896 8.33675 6.11937 8.53563 6.11937C8.73451 6.11937 8.89577 5.95896 8.89577 5.76113V5.45065C8.89574 5.25279 8.73451 5.09241 8.53563 5.09241Z" fill="#343A40" />
		</svg>
	);
};