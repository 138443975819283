export const IcoVacacional = ({ w = 64, h = 64, color = '#3200C1' }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={w} height={h} viewBox="0 0 64 64" fill="none">
			<g clipPath="url(#clip0_104_1854)">
				<path d="M58.6436 54.3496C49.5115 47.2349 38.7738 51.4026 28.1899 54.1623L23.4209 36.3629C24.3545 36.3733 25.2991 36.6062 26.1772 37.0828C26.9422 37.526 27.9044 37.2572 28.3461 36.5267C29.8457 34.0471 33.0673 32.7801 36.2817 34.4243C37.5112 35.0531 38.9031 33.9249 38.552 32.5956C36.2407 23.8443 27.6529 18.5584 19.0475 20.0397L18.2898 17.2114C18.0616 16.3595 17.186 15.8535 16.3337 16.082C15.4816 16.3103 14.9761 17.1861 15.2044 18.0383L15.9621 20.8663C7.73203 23.8998 2.97657 32.8014 5.35249 41.5099C5.71975 42.8555 7.53366 43.1205 8.26093 41.9144C9.82663 39.3183 13.1777 38.1937 16.1871 39.7992C16.9433 40.2006 17.8707 39.9359 18.3053 39.2174C18.826 38.3563 19.5299 37.6753 20.3366 37.1941L25.0863 54.9214C18.98 56.2943 12.8438 56.834 7.00551 54.1577C6.20381 53.7902 5.2557 54.1423 4.88813 54.9441C4.52055 55.746 4.87258 56.6941 5.67449 57.0617C23.9773 65.4517 43.1926 46.3609 56.6804 56.8697C57.3761 57.4117 58.3797 57.2872 58.9219 56.5914C59.464 55.8954 59.3394 54.8917 58.6436 54.3496ZM34.3529 30.526C32.0615 30.2819 29.7533 30.9224 27.9131 32.3159C26.8625 28.7466 24.8907 25.5127 22.0781 22.9856C27.1576 23.0309 31.9601 25.9039 34.3529 30.526ZM7.94979 37.6071C7.71425 32.5213 10.3355 27.5628 14.8243 24.9247C13.6625 28.4842 13.5499 32.2692 14.4336 35.925C12.1374 35.6377 9.81385 36.2417 7.94979 37.6071ZM17.5585 35.2554C16.6493 31.574 16.9094 27.7412 18.5173 24.2157C21.6712 26.4632 23.815 29.6441 24.8707 33.294C22.2669 32.8507 19.5912 33.5683 17.5585 35.2554Z" fill={color} />
				<path d="M35.1192 19.2174H36.8176C37.6997 19.2174 38.4147 18.5023 38.4147 17.6202C38.4147 16.7381 37.6996 16.0229 36.8176 16.0229H35.1192C34.2371 16.0229 33.5221 16.7381 33.5221 17.6202C33.5221 18.5023 34.2371 19.2174 35.1192 19.2174Z" fill={color} />
				<path d="M46.3808 22.9873C49.34 22.9873 51.7476 20.5796 51.7476 17.6203C51.7476 14.6608 49.34 12.2531 46.3808 12.2531C43.4216 12.2531 41.014 14.6608 41.014 17.6203C41.014 20.5796 43.4216 22.9873 46.3808 22.9873ZM46.3808 15.4475C47.5787 15.4475 48.5532 16.4221 48.5532 17.6201C48.5532 18.8181 47.5787 19.7928 46.3808 19.7928C45.1829 19.7928 44.2084 18.8182 44.2084 17.6201C44.2084 16.4221 45.1829 15.4475 46.3808 15.4475Z" fill={color} />
				<path d="M46.3808 9.63376C47.2629 9.63376 47.9779 8.91863 47.9779 8.03654V6.33794C47.9779 5.45585 47.2628 4.74072 46.3808 4.74072C45.4988 4.74072 44.7837 5.45585 44.7837 6.33794V8.03643C44.7837 8.91863 45.4987 9.63376 46.3808 9.63376Z" fill={color} />
				<path d="M38.4752 11.9729C39.0988 12.5966 40.1103 12.5967 40.7339 11.9729C41.3577 11.3491 41.3577 10.3378 40.7339 9.71395L39.533 8.51295C38.9094 7.88908 37.898 7.88908 37.2743 8.51295C36.6506 9.13672 36.6506 10.148 37.2743 10.7718L38.4752 11.9729Z" fill={color} />
				<path d="M38.4752 23.2673L37.2743 24.4684C36.6506 25.0921 36.6506 26.1034 37.2743 26.7273C37.8979 27.351 38.9094 27.3511 39.533 26.7273L40.7339 25.5262C41.3577 24.9025 41.3577 23.8912 40.7339 23.2673C40.1103 22.6435 39.0989 22.6435 38.4752 23.2673Z" fill={color} />
				<path d="M44.7837 27.2038V28.9022C44.7837 29.7843 45.4988 30.4994 46.3808 30.4994C47.2628 30.4994 47.9779 29.7843 47.9779 28.9022V27.2038C47.9779 26.3217 47.2628 25.6066 46.3808 25.6066C45.4988 25.6066 44.7837 26.3217 44.7837 27.2038Z" fill={color} />
				<path d="M52.0276 23.2673C51.4039 23.8911 51.4039 24.9024 52.0276 25.5262L53.2285 26.7273C53.8521 27.351 54.8636 27.3511 55.4872 26.7273C56.111 26.1035 56.111 25.0922 55.4872 24.4684L54.2863 23.2673C53.6627 22.6435 52.6513 22.6435 52.0276 23.2673Z" fill={color} />
				<path d="M54.3666 17.62C54.3666 18.5021 55.0817 19.2173 55.9637 19.2173H57.6621C58.5442 19.2173 59.2592 18.5021 59.2592 17.62C59.2592 16.738 58.5441 16.0228 57.6621 16.0228H55.9637C55.0816 16.0228 54.3666 16.738 54.3666 17.62Z" fill={color} />
				<path d="M54.2863 11.9729L55.4872 10.7718C56.111 10.1481 56.111 9.13682 55.4872 8.51295C54.8636 7.88908 53.8522 7.88908 53.2285 8.51295L52.0276 9.71395C51.4039 10.3377 51.4039 11.349 52.0276 11.9729C52.6512 12.5966 53.6627 12.5967 54.2863 11.9729Z" fill={color} />
			</g>
			<defs>
				<clipPath id="clip0_104_1854">
					<rect width="54.5185" height="54.5185" fill="white" transform="translate(4.74072 4.74072)" />
				</clipPath>
			</defs>
		</svg>
	);
};