import React from 'react';
import { IcoInstagram, IcoFacebook, IcoYoutube, IcoLinkedin2 } from '../../../iconos';
import styles from './RRSS.module.scss'

const RRSS = (props) => {
	return (
		<div className={`rrss ${styles.rrss} ${props.className}`}>
			<a href="https://www.instagram.com/toctoccom?o=footer" target={'_blank'} rel="noreferrer"><IcoInstagram /></a>
			<a href="https://www.facebook.com/toctoccom?o=footer" target={'_blank'} rel="noreferrer"><IcoFacebook /></a>
			<a href="https://www.linkedin.com/company/toctoc.com?o=footer" target={'_blank'} rel="noreferrer"><IcoLinkedin2 /></a>
			<a href="https://www.youtube.com/user/TocTocChile?o=footer" target={'_blank'} rel="noreferrer"><IcoYoutube /></a>
		</div>
	);
};

export default RRSS;