import { AccessToctoc } from "./access";
import { AclCheckAction } from "./enums";
import { menuAliasACL } from "./menuAliasACL";
import { AclCheckActionType, SchemaPermission } from "./types.d";

export default class ACLToctoc {
	private _schema: SchemaPermission;
	private _splitNumber: number = 5;

	constructor(schema: SchemaPermission = {}) {
		this._schema = schema;
	}

	private decomponeRole(role: string) {
		return role.split(".").map((r) => Number(r));
	}

	roleIsValid(role: string) {
		if (!role || role.split(".").length !== this._splitNumber) {
			return false;
		}
		return Number(role.replace(/\./g, "")) > 0;
	}

	check(section: string, role: string) {
		if (!section) {
			throw new Error("invalid section");
		}
		if (!this.roleIsValid(role)) {
			throw new Error("invalid role");
		}

		const roleSplit = this.decomponeRole(role);
		return new AccessToctoc(this._schema, section, roleSplit);
	}

	checkAction(section: string, role: string, action: AclCheckActionType) {
		if (!section || !action) {
			throw new Error("invalid section/action");
		}
		if (!this.roleIsValid(role)) {
			throw new Error("invalid role");
		}
		const roleSplit = this.decomponeRole(role);
		const acl = new AccessToctoc(this._schema, section, roleSplit);
		if (action === AclCheckAction.READ) return acl.canRead();
		if (action === AclCheckAction.WRITE) return acl.canWrite();
		if (action === AclCheckAction.DELETE) return acl.canDelete();
		return false;
	}

	checkInternal(role: string) {
		if (!this.roleIsValid(role)) {
			throw new Error("invalid role");
		}
		return this.decomponeRole(role)[2] > 0;
	}

	checkExternal(role: string) {
		if (!this.roleIsValid(role)) {
			throw new Error("invalid role");
		}
		return this.decomponeRole(role)[1] > 0;
	}

	checkAccess(role: string) {
		if (!this.roleIsValid(role)) {
			throw new Error("invalid role");
		}
		return this.decomponeRole(role)[0] === 1;
	}
	getProfileCode(role: string) {
		if (!this.roleIsValid(role)) {
			throw new Error("invalid role");
		}
		const digit = this.decomponeRole(role);
		return `${digit[1]}.${digit[2]}`;
	}
	filterMenuOptions<T extends { name: string, href: string }>({ role, userMenu }: { role: string, userMenu: T[] }) {
		if (!this.roleIsValid(role)) return []
		const tempRole = this.getProfileCode(role)
		return userMenu.filter((item) => {
			if (menuAliasACL[item.name]?.roles && !menuAliasACL[item.name]?.roles?.includes(tempRole)) {
				return false
			}
			if (menuAliasACL[item.name]) {
				return this.check(menuAliasACL[item.name].action, role).canRead()
			}
			return false
		}).map(item => {			
			const path = menuAliasACL?.[item.name]?.pathByRol?.[tempRole]
			if (path && item.href && !item.href.includes(path)) {
				item.href = item.href.replace("/admin/", path);
			}
			return item
		})
	}
}