export const IcoParcelas = ({ w = 64, h = 64, color = '#3200C1' }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={w} height={h} viewBox="0 0 64 64" fill="none">
			<line x1="4" y1="11.25" x2="60" y2="11.25" stroke={color} strokeWidth="3" />
			<line x1="4" y1="50.625" x2="60" y2="50.625" stroke={color} strokeWidth="3" />
			<line x1="13.5" y1="4" x2="13.5" y2="60" stroke={color} strokeWidth="3" />
			<line x1="53.5" y1="4" x2="53.5" y2="60" stroke={color} strokeWidth="3" />
			<path d="M36.2727 42.9404V36.5827C36.2727 36.3016 36.1578 36.0321 35.9532 35.8334C35.7486 35.6347 35.4711 35.5231 35.1818 35.5231H30.8182C30.5289 35.5231 30.2514 35.6347 30.0468 35.8334C29.8422 36.0321 29.7273 36.3016 29.7273 36.5827V42.9404C29.7273 43.2214 29.6123 43.4909 29.4078 43.6896C29.2032 43.8884 28.9257 44 28.6364 44H22.0909C21.8016 44 21.5241 43.8884 21.3195 43.6896C21.1149 43.4909 21 43.2214 21 42.9404V30.6886C21.0024 30.5419 21.0351 30.3972 21.096 30.263C21.1568 30.1288 21.2448 30.0078 21.3545 29.9071L32.2636 20.2778C32.4647 20.0991 32.7274 20 33 20C33.2726 20 33.5353 20.0991 33.7364 20.2778L44.6455 29.9071C44.7552 30.0078 44.8432 30.1288 44.904 30.263C44.9649 30.3972 44.9976 30.5419 45 30.6886V42.9404C45 43.2214 44.8851 43.4909 44.6805 43.6896C44.4759 43.8884 44.1984 44 43.9091 44H37.3636C37.0743 44 36.7968 43.8884 36.5922 43.6896C36.3877 43.4909 36.2727 43.2214 36.2727 42.9404Z" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
		</svg>
	);
};