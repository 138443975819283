export const IcoTransparentamos = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="40"
			height="40"
			fill="none"
			viewBox="0 0 40 40"
		>
			<path stroke="#3200C1" strokeWidth="2" d="M2.5 6.969L37.5 6.969"></path>
			<path stroke="#3200C1" strokeWidth="2" d="M2.5 31.578L37.5 31.578"></path>
			<path stroke="#3200C1" strokeWidth="2" d="M8.5 2.5L8.5 37.5"></path>
			<path stroke="#3200C1" strokeWidth="2" d="M33.5 2.5L33.5 37.5"></path>
			<path
				stroke="#3200C1"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="2"
				d="M22.67 26.838v-3.974a.653.653 0 00-.2-.468.692.692 0 00-.481-.194H19.26c-.18 0-.354.07-.482.194a.653.653 0 00-.2.468v3.974a.653.653 0 01-.2.468.692.692 0 01-.481.194h-4.091a.692.692 0 01-.482-.194.653.653 0 01-.2-.468V19.18a.67.67 0 01.222-.488l6.818-6.018a.693.693 0 01.92 0l6.818 6.018a.687.687 0 01.222.488v7.658a.653.653 0 01-.2.468.692.692 0 01-.482.194h-4.09a.692.692 0 01-.483-.194.653.653 0 01-.2-.468z"
			></path>
		</svg>
	);
};

export default IcoTransparentamos;