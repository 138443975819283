
export const replaceAccents = (text: string): string => {
	if (text === null || text === undefined)
		return null;

	const accentLetterList = [
		["á", "a"],
		["é", "e"],
		["í", "i"],
		["ó", "o"],
		["ú", "u"],
		["ñ", "n"]
	];
	let returnText = text
	accentLetterList.forEach(letterPair => {
		const [accented, latin] = letterPair
		const regex = new RegExp(accented, 'ig')
		returnText = returnText.replace(regex, latin)
	});
	return returnText
};


export const validateEmail = (email: string): boolean => {
	if (email === null || email === undefined)
		return false;
	const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
}

export const readRawHeaderCookie = (rawCookie: string, key: string): string => {
	if (rawCookie === undefined || rawCookie === null || rawCookie === "" || rawCookie.trim() === "")
		return null;
	if (key === undefined || key === null || key === "" || key.trim() === "")
		return null;
	
	const cookies = rawCookie?.split(";") ?? [];
	const rawKeyValue = cookies.find(cookie => cookie.includes(key));
	if (rawKeyValue === undefined || rawKeyValue === null || rawKeyValue === "")
		return null;
	
	const parsedKeyValue = rawKeyValue.split("=").pop() ?? null;

	return parsedKeyValue
}