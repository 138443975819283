/**
 * 
 * @see Thanks to https://dev.to/capscode/how-to-detect-mobile-device-os-using-javascript-4l9j
 * @see Pointer selector https://developer.mozilla.org/en-US/docs/Web/CSS/@media/pointer
 */
export function isMobile() {
	if (typeof window === "undefined")
		return false;
	const isMobile = window.matchMedia || window.msMatchMedia;
	if (!isMobile)
		return false;

	const match_mobile = isMobile("(pointer:coarse)");
	return match_mobile.matches;

}

const USER_AGENT_LIST = [
	["IE11", "Trident/7.0"],
	["IE10", "MSIE"],
	["Opera 12", "Opera"],
	["Opera 15", "OPR"],
	["Chromium", "Chromium"],
	["Chrome", "Chrome"],
	["Safari", "Safari"],
	["Seamonkey", "Seamonkey"],
	["Firefox", "Firefox"]
];

export function getBrowser() {
	if (typeof window === "undefined" || typeof navigator === 'undefined' || Object.keys(navigator).length === 0) return "No Browser";
	const userAgent = navigator.userAgent;
	const [browser] = USER_AGENT_LIST.find(ua => {
		const [, uaString] = ua;
		return userAgent.includes(uaString);
	});

	return browser ?? userAgent.split(" ").pop().split("/").shift();
}

/**
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent
 */
export const isMobileByUserAgent = (userAgentText) => {
	if (userAgentText === undefined || userAgentText === null || userAgentText === "") return false;
	return /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(userAgentText) ||
		/\b(Android|Windows Phone|iPad|iPod)\b/i.test(userAgentText);
}