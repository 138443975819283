import { KeyValuePair } from "./types";

export const extractQueryParameters = (url: string): Array<KeyValuePair> => {

	const querySeparator = "?";
	const paramSeparator = "&";
	const keyValueSeparator = "=";

	if (url === null || url === undefined)
		return null;


	if (url.indexOf(querySeparator) === -1)
		return null;

	const queryParams = url.split(querySeparator).pop();

	if (queryParams === null || queryParams === undefined)
		return null;

	const hasQueryParameters = queryParams.indexOf(paramSeparator) !== -1;

	const paramList = hasQueryParameters ? queryParams.split(paramSeparator) : [queryParams];

	return paramList.map((param) => {
		if (param === null || param === undefined || param.indexOf(keyValueSeparator) === -1)
			return null;
		const [key, value] = param.split(keyValueSeparator);
		return { key, value };
	}).filter(p => p);

}

// toctoc.com -> return ".toctoc.com" / www.toctoc.com -> return ".toctoc.com"
export const extractLastPartUrl = (urlBase: string) => {
	if (urlBase === "") return "";
	try {
		let url = new URL(urlBase);
		if (url.hostname === 'localhost') return url.hostname;
		let urlSeparate = url.hostname.split(".");
		if (urlSeparate.length > 2) {
			let lastTwoPartsUrl = urlSeparate.slice(-2);
			let urlExtract = `.${lastTwoPartsUrl.shift()}.${lastTwoPartsUrl.pop()}`
			return urlExtract;
		}
	} catch {
		return "";
	}
}

export const tryToGetUrlHost = (url:string) => {
	try {
		if (url === undefined || url === null || url.trim() === "")
			return null;
		const urlObject = new URL(url);
		return `${urlObject.protocol}${urlObject.hostname}`
	} catch (ex) {
		return null;
	}
}