export const IcoAgricola = ({ w = 25, h = 61, color = '#3200C1' }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={w} height={h} viewBox="0 0 25 61" fill="none">
			<path d="M12.5 3C12.5 3 4.93481 9.74429 12.5 17.6797C20.0652 9.74429 12.5 3 12.5 3Z" stroke={color} strokeWidth="3" strokeMiterlimit="10" />
			<path d="M2.0053 17.0053C2.0053 17.0053 1.42584 27.1298 12.3873 27.3873C12.1298 16.4258 2.0053 17.0053 2.0053 17.0053Z" stroke={color} strokeWidth="3" strokeMiterlimit="10" />
			<path d="M22.9947 17.0053C22.9947 17.0053 23.5742 27.1298 12.6127 27.3873C12.8702 16.4258 22.9947 17.0053 22.9947 17.0053Z" stroke={color} strokeWidth="3" strokeMiterlimit="10" />
			<path d="M2.0053 30.0053C2.0053 30.0053 1.42584 40.1298 12.3873 40.3873C12.1298 29.4258 2.0053 30.0053 2.0053 30.0053Z" stroke={color} strokeWidth="3" strokeMiterlimit="10" />
			<path d="M22.9947 30.0053C22.9947 30.0053 23.5742 40.1298 12.6127 40.3873C12.8702 29.4258 22.9947 30.0053 22.9947 30.0053Z" stroke={color} strokeWidth="3" strokeMiterlimit="10" />
			<path d="M2.0053 43.0057C2.0053 43.0057 1.42584 53.1302 12.3873 53.3877C12.1298 42.4102 2.0053 43.0057 2.0053 43.0057Z" stroke={color} strokeWidth="3" strokeMiterlimit="10" />
			<path d="M22.9947 43.0057C22.9947 43.0057 23.5742 53.1302 12.6127 53.3877C12.8702 42.4102 22.9947 43.0057 22.9947 43.0057Z" stroke={color} strokeWidth="3" strokeMiterlimit="10" />
			<path d="M12.5 17.567V59.24" stroke={color} strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
		</svg>
	);
};