
export const IcoCorazon = ({ w = 32, h = 32 }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg"
			width={w}
			height={h}
			viewBox="0 0 32 32"
			fill="none">
			<circle opacity="0.9" cx="16" cy="16" r="16" transform="rotate(-180 16 16)" fill="#F9F9F9" />
			<path d="M23.0242 18.3554L23.0241 18.3553L23.0166 18.3658C22.039 19.7158 20.8597 20.8939 19.6306 21.9406C19.6193 21.9502 19.6082 21.9601 19.5973 21.9702C19.037 22.4922 18.1162 23.2815 17.2555 23.9437C16.8243 24.2754 16.4242 24.5628 16.1013 24.7662C15.9871 24.8382 15.8904 24.8946 15.8113 24.9369C15.7259 24.8888 15.6129 24.8156 15.4693 24.7076C15.3349 24.6065 15.2008 24.4965 15.0559 24.3775L15.0438 24.3676C14.9128 24.26 14.7639 24.1376 14.6224 24.0333C11.9015 21.9631 9.13856 19.5611 7.75833 17.0204C6.63 14.6062 6.73009 11.8506 8.25549 10.2275C10.3447 8.37075 13.4655 8.7565 15.0312 10.686C15.2238 10.9235 15.5146 11.0597 15.8203 11.0558C16.1261 11.052 16.4133 10.9084 16.5999 10.6662C17.018 10.1234 17.526 9.69738 18.1317 9.38164C20.697 8.37973 23.2618 9.42013 24.321 11.6401C25.0662 14.0155 24.4306 16.4648 23.0242 18.3554Z" stroke="#3200C1" strokeWidth="2" strokeLinejoin="round" />
		</svg>
	);
};