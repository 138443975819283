/* eslint-disable no-unused-vars */
export const enum Permissions {
  NO_ACTIONS = 0,
  READ = 1,
  WRITE = 2,
  DELETE = 4,
  READ_WRITE = 3,
  READ_DELETE = 5,
  WRITE_DELETE = 6,
  READ_WRITE_DELETE = 7,
}

export const enum AclCheckAction {
  READ = "read",
  WRITE = "write",
  DELETE = "delete",
}

// este no estaba en el enums original del admin
export const enum ePerfilRole {
	usuarioLogueado = "1.0",
	clienteCorredorMatriz = "2.0",
	clienteCorredora = "4.0",
	clienteInmobiliaria = "8.0",
	clienteCorredor = "16.0",
	clienteEjecutivo = "32.0",
	multifamily = "64.0",
	soporte = "0.1",
	qaBot = "0.2",
	admin = "0.4",
	superAdmin = "0.8",
	clienteCorredoraIntegrada = "128.0",
	Integradora = "256.0",
	PublicadorIntegrado = "512.0"
}