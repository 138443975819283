import { getCookie } from 'components/login/view/session';
import { useState, useEffect, useCallback } from 'react';
import { useSessionStorage } from './useSessionStorage';

const SESSION_STORAGE_KEY = 'currentSessionId';
export function useSession({ sessionServices }) {
	const {
		getCookieList,
		getServerDomain,
		validateToken,
		getUserInfoByToken,
		getInformationUser
	} = sessionServices;
	const [sessionState, setSessionState] = useState({ logeado: null, user: null, sessionStorageKey: SESSION_STORAGE_KEY });
	const [localSession, setLocalSession] = useSessionStorage(SESSION_STORAGE_KEY, 0);
	const clearSessionCookies = useCallback(() => async () => {
		const awaitCookieList = await getCookieList();
		const serverDomain = await getServerDomain();
		awaitCookieList.forEach(cookieName => {
			document.cookie = `${cookieName}=; Path=/; domain=${serverDomain}; Expires=Thu, 01 Jan 1970 00:00:01 GMT; SameSite=lax;`;
			document.cookie = `${cookieName}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
		});
		setSessionState(() => ({
			logeado: null,
			user: null
		}));
	}, []);

	const initSessionCookie = useCallback(() => async (cookiett) => {

		let user = await getUserInfoByToken(cookiett).catch(error => {
			// console.error(error);
		});

		if (!user || user.email === undefined || user.email === '') return undefined;

		setSessionState((prev) => ({
			...prev,
			logeado: user !== null && user.email !== undefined,
			user: { name: user.name, email: user.email, id: localSession, role: user.role }
		}));

		return undefined;
	}, [getUserInfoByToken, localSession]);

	useEffect(() => {
		if (!document && document.cookie === undefined) return undefined;
		if (sessionState !== null && sessionState.user !== null && sessionState.user !== undefined && sessionState.user.id !== 0) return undefined;
		if (localSession === 0) return;
		const cookie = document.cookie;
		const cookieToctoc = getCookie(cookie);

		initSessionCookie()(cookieToctoc);

	}, [initSessionCookie, sessionState, localSession]);

	useEffect(() => {
		if (sessionState.logeado === false)
			clearSessionCookies()();
	}, [sessionState.logeado, clearSessionCookies]);

	const setupCallback = useCallback(() => {
		const cookie = document.cookie;
		const cookieToctoc = getCookie(cookie);

		if (validateToken !== null && validateToken !== undefined && cookieToctoc !== null && cookieToctoc !== undefined) {
			validateToken(cookieToctoc)
				.then((isValidToken) => {
					if (isValidToken.status) {
						let emailUser = getUserInfoByToken(cookieToctoc);
						return emailUser;
					}
				})
				.then((user) => {

					setSessionState((prev) => ({
						...prev,
						logeado: true,
						user,
					}));
					let informationUser = getInformationUser(user?.email);
					return informationUser;
				})
				.catch(error => {
					console.error(error);
				})
				.then((informationUser) => {
					const { data } = informationUser ?? {};
					const { nombres, perfilesUsuario, fechaCreacion, email, idUsuario } = data ?? {};
					const createDateValue = fechaCreacion ? new Date(fechaCreacion).getTime() : Date.now();
					if (nombres !== undefined) {
						const userFromRequest = {
							id: idUsuario,
							name: nombres,
							roles: perfilesUsuario !== undefined && perfilesUsuario.length > 0 ? perfilesUsuario.map(p => p.idPerfil) : 0,
							email,
							createDate: Math.floor(createDateValue / 1000)
						};
						setLocalSession(idUsuario);
						setSessionState((prev) => ({
							...prev,
							logeado: true,
							user: { ...prev.user, ...userFromRequest }
						}));
					}
				})
				.catch(error => {
					console.error(error);
				});
		}
	}, []);

	return [sessionState, setSessionState, setupCallback];
}