// 5) Landing SEO Venta General

// 5.1. Impresion Banner Inmobiliaria
export const impresionBannerInmobiliaria = (dataLayer, { idUsuario, nombreBanner }) => {
	dataLayer?.push?.({
		'event': 'banners-inmobiliaria',
		'user-id': idUsuario,
		'banner': nombreBanner,
		'apartado': 'Landing Venta Banner Inmobiliaria'
	})
	return true;
}

// 5.2. Clic Banner Inmobiliaria
export const clicBannerInmobiliaria = (dataLayer, { idUsuario, nombreBanner }) => {
	dataLayer?.push?.({
		'event': 'clic-banner-inmobiliaria',
		'user-id': idUsuario,
		'banner': nombreBanner,
		'apartado': 'Landing Venta Banner Inmobiliaria'
	})
}

// 5.3. Impresion Banners Comunas
export const impresionBannersComunas = (dataLayer, { idUsuario, nombreBanners }) => {
	dataLayer?.push?.({
		'event': 'banners-comunas',
		'user-id': idUsuario,
		'banners': nombreBanners,
		'apartado': 'Landing Venta Comunas'
	})
	return true;
}

// 5.4. Clic Banner Comunas
export const clicBannersComunas = (dataLayer, { idUsuario, nombreBanner }) => {
	dataLayer?.push?.({
		'event': 'clic-banner-comunas',
		'user-id': idUsuario,
		'banner': nombreBanner,
		'apartado': 'Landing Venta Comunas'
	})
}

// 5.5. Impresion Calugas Tipo Propiedades
export const impresionCalugasTipoPropiedades = (dataLayer, { idUsuario, nombreCalugas }) => {
	dataLayer?.push?.({
		'event': 'calugas-tipo-propiedades',
		'user-id': idUsuario,
		'banners': nombreCalugas,
		'apartado': 'Landing Venta Tipos Propiedad'
	})
	return true;
}

// 5.6. Clic Calugas Tipo Propiedades
export const clicCalugasTipoPropiedades = (dataLayer, { idUsuario, nombreCaluga }) => {
	dataLayer?.push?.({
		'event': 'clic-caluga-tipo-propiedad',
		'user-id': idUsuario,
		'banner': nombreCaluga,
		'apartado': 'Landing Venta Tipos Propiedad'
	})
}

// 5.7. Impresion Banners Especiales de Venta
export const impresionBannersEspecialesDeVenta = (dataLayer, { idUsuario, nombreBanners }) => {
	dataLayer?.push?.({
		'event': 'banners-especiales-de-venta',
		'user-id': idUsuario,
		'banners': nombreBanners,
		'apartado': 'Landing Venta Especiales Venta'
	})
	return true;
}

// 5.8. Clic Banners Especiales de Venta
export const clicBannersEspecialesDeVenta = (dataLayer, { idUsuario, nombreBanner }) => {
	dataLayer?.push?.({
		'event': 'clic-banner-especiales-de-venta',
		'user-id': idUsuario,
		'banner': nombreBanner,
		'apartado': 'Landing Venta Especiales Venta'
	})
}