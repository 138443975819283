export const IcoChevron = ({ w = 16, h = 9, fill = "", color = '#3200C1', clase = '' }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="9"
			viewBox="0 0 16 9"
			fill="none"
			className={clase}
		>
			<path d="M2 2L6.58579 6.58579C7.36683 7.36683 8.63317 7.36684 9.41421 6.58579L14 2" stroke={color} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square" />
		</svg>
	);
};