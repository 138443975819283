const loggerBuffer = (() => {
	const exceptions = [];
	const messages = [];
	const breadcrumbs = [];

	return {
		putException: (data) => exceptions.push(data),
		putMessage: (data) => messages.push(data),
		putBreadcrumbs: (data) => breadcrumbs.push(data),
		getExceptions: () => [...exceptions],
		getMessages: () => [...messages],
		getBreadcrumbs: () => [...breadcrumbs],
	};
})();

const loggerLocal = {
	captureException: (ex) => { loggerBuffer.putException(ex); return loggerLocal; },
	captureMessage: (message) => { loggerBuffer.putMessage(message); return loggerLocal; },
	addBreadcrumb: (data) => { loggerBuffer.putBreadcrumbs(data); return loggerLocal; }
};

const showDataList = (array, emptyMessage = "No data") => array.length > 0 ? array : emptyMessage;

loggerLocal.debug = () => {
	console.debug(
		"[TIME]", new Date().toUTCString(), "\n\n",
		"[getException]",
		"\n", showDataList(loggerBuffer.getExceptions()), "\n",
		"[getMessage]",
		"\n", showDataList(loggerBuffer.getMessages()), "\n",
		"[getBreadcrumbs]",
		"\n", showDataList(loggerBuffer.getBreadcrumbs()), "\n"
	);
};

export { loggerLocal };