import React, { useEffect } from "react";
import { setupEventListener } from '@toctoc/toctoc-event-tracker';

import {
	busquedaNormal,
	busquedaCodigo,
	deslizarBanners,
	clicBanner,
	impresionCards,
	deslizarPropiedades,
	clicCambioDeTipoPropiedad,
	clicPropiedadDestacada,
	clicFavoritos,
	clicPublicar,
	clicDescargarApp,
	impresionBanners,
	impresionBannerInmobiliaria,
	clicBannerInmobiliaria,
	impresionBannersComunas,
	clicBannersComunas,
	impresionCalugasTipoPropiedades,
	clicCalugasTipoPropiedades,
	impresionBannersEspecialesDeVenta,
	clicBannersEspecialesDeVenta,
	impresionProductosEmpresa,
	impresionCargaEmpresas,
	clickProductosEmpresa,
	clickTipoPropiedad,
	clickVerMasEmpresas,
	impresionBannerCorredora,
	impresionTipoPropiedad,
	impresionBannerEspeciales,
	impresionBannerComunas,
	clickBannerCorredora,
	clickArriendoTiposPropiedad,
	clickBannersEspeciales,
	clickBannersComunas,
	clickProductosPersona
} from './eventos';

const eventCaptureList = [
	{ event: 'load' },
	{ event: 'click' },
	{ event: 'scroll' },
];

const elementSelectorListHomePersonaClick = [
	{ callback: busquedaNormal, selector: "#hp-btn-buscar-normal" },
	{ callback: busquedaCodigo, selector: "#hp-btn-buscar-codigo" },
	{ callback: deslizarBanners, selector: "#nextButton" },
	{ callback: deslizarBanners, selector: "#nextButton *" },
	{ callback: deslizarBanners, selector: "#previousButton" },
	{ callback: deslizarBanners, selector: "#previousButton *" },
	{ callback: deslizarBanners, selector: "#hp-contenedor-carrusel .swiper-pagination-bullet" },
	{ callback: clicBanner, selector: "#hp-contenedor-img-banner a *" },
	{ callback: deslizarPropiedades, selector: ".swiper-button-prev" },
	{ callback: deslizarPropiedades, selector: ".swiper-button-next" },
	{ callback: clicCambioDeTipoPropiedad, selector: "#hp-tipo-propiedad-destacada" },
	{ callback: clicPropiedadDestacada, selector: "#hp-contenedor-card-propiedades-destacadas a" },
	{ callback: clicPropiedadDestacada, selector: "#hp-contenedor-card-propiedades-destacadas a img" },
	{ callback: clicFavoritos, selector: "#hp-boton-favorita" },
	{ callback: clicPublicar, selector: "#hp-btn-publicar" },
	{ callback: clicDescargarApp, selector: "#hp-btn-app a img" },
	{ callback: clickProductosPersona, selector: ".productoCardButton a" },

];

const elementSelectorListListaSEOVentaClick = [
	{ callback: clicBannerInmobiliaria, selector: "#lseov-contenedor-banner-inmobiliaria a img" },
	{ callback: clicBannersComunas, selector: "#lseov-contenedor-propiedades a" },
	{ callback: clicCalugasTipoPropiedades, selector: "#lseov-contenedor-tipo-propiedad *" },
	{ callback: clicBannersEspecialesDeVenta, selector: "#lseov-contenedor-especiales-venta a *" },
];

const elementSelectorListHomePersonaImpresion = [
	{ callback: impresionBanners, selector: "#hp-contenedor-carrusel", amount: 1 },
	{ callback: impresionCards, selector: "#hp-contenedor-card-destacado", amount: 1 }
];

const elementSelectorListHomeEmpresaClick = [
	{ callback: clickProductosEmpresa, selector: ".productosEmpresa .btnOutline" },
	{ callback: clickTipoPropiedad, selector: ".clients a" },
	{ callback: clickVerMasEmpresas, selector: ".clientVerMas a" },
];

const elementSelectorListListaSEOVentaImpresion = [
	{ callback: impresionBannerInmobiliaria, selector: "#lseov-contenedor-banner-inmobiliaria a img", amount: 1 },
	{ callback: impresionBannersComunas, selector: "#lseov-contenedor-propiedades", amount: 1 },
	{ callback: impresionCalugasTipoPropiedades, selector: "#lseov-contenedor-propiedades-venta", amount: 1 },
	{ callback: impresionBannersEspecialesDeVenta, selector: "#lseov-contenedor-especiales-venta", amount: 1 },
];

const elementSelectorListSEOArriendoClick = [
	{ callback: clickBannerCorredora, selector: "#bannerCorredora" },
	{ callback: clickArriendoTiposPropiedad, selector: ".tipoPropiedadLink, .tipoPropiedadLink div" },
	{ callback: clickBannersComunas, selector: "#bannersComunas a" },
	{ callback: clickBannersEspeciales, selector: "#bannersEspeciales a, #bannersEspeciales h3" },
];

const elementSelectorListHomeEmpresaImpresion = [
	{ callback: impresionProductosEmpresa, selector: ".productosEmpresa#Publicarproyectos", amount: 1 },
	{ callback: impresionProductosEmpresa, selector: ".productosEmpresa#Publicarpropiedades", amount: 1 },
	{ callback: impresionProductosEmpresa, selector: ".productosEmpresa#Infoinmobiliario", amount: 1 },
	{ callback: impresionProductosEmpresa, selector: ".productosEmpresa#Inforenta", amount: 1 },
	{ callback: impresionProductosEmpresa, selector: ".productosEmpresa#Infogis", amount: 1 },
	{ callback: impresionProductosEmpresa, selector: ".productosEmpresa#Addinmobiliario", amount: 1 },
	{ callback: impresionCargaEmpresas, selector: "#clientesSwiper", amount: 1 },
];

const elementSelectorListSEOArriendoImpresion = [
	{ callback: impresionBannerCorredora, selector: "#bannerCorredora", amount: 1 },
	{ callback: impresionTipoPropiedad, selector: "#tipoPropiedad", amount: 1 },
	{ callback: impresionBannerEspeciales, selector: "#bannersEspeciales", amount: 1 },
	{ callback: impresionBannerComunas, selector: "#bannersComunas", amount: 1 },
];

const elementSelectorList = [
	{
		type: "click",
		selectorPairList: [
			...elementSelectorListHomePersonaClick,
			...elementSelectorListHomeEmpresaClick,
			...elementSelectorListListaSEOVentaClick,
			...elementSelectorListSEOArriendoClick
		]
	},
	{
		type: "scroll",
		indirect: true,
		selectorPairList: [
			...elementSelectorListHomePersonaImpresion,
			...elementSelectorListHomeEmpresaImpresion,
			...elementSelectorListListaSEOVentaImpresion,
			...elementSelectorListSEOArriendoImpresion
		]
	},
	{
		type: "view",
		selectorPairList: [
		]
	},
];

const setupEventFunction = () => setupEventListener({
	window,
	baseObserver: () => document.querySelector("body"),
	// secondaryDOMElementList: () => document.querySelectorAll('.modal'),
	secondaryDOMElementList: () => [],
	dataLayer: window.dataLayer,
	elementSelectorList,
	eventCaptureList
});

export const GTMEventos = () => {

	useEffect(() => {
		if (typeof window !== "undefined") {
			return setupEventFunction();
		}
	}, []);

	return (<></>);
};