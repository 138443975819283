export const IcoMenu = ({ w = 24, h = 24, fill = "", color = 'white' }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg"
			width={w}
			height={h}
			viewBox="0 0 22 17"
			fill="none">
			<path d="M1 1H21M1 8.22222H21M1 15.4444H21" stroke={color} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
		</svg>
	);
};