export const IcoMegafono = ({ w = 40, h = 40, fill = "", stroke = '#3200C1', className = '' }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg"
			width={w}
			height={h}
			viewBox="0 0 40 40"
			className={className}
			fill="none">
			<path d="M23.75 12.5V31.8281C23.7481 32.0359 23.7986 32.2407 23.897 32.4237C23.9953 32.6067 24.1382 32.7619 24.3125 32.875L26.0313 34.0156C26.1978 34.1268 26.3885 34.1963 26.5875 34.2184C26.7865 34.2405 26.9879 34.2144 27.1747 34.1425C27.3615 34.0705 27.5283 33.9547 27.6611 33.8048C27.7938 33.655 27.8886 33.4754 27.9375 33.2812L30 25" stroke={stroke} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M30 25C31.6576 25 33.2473 24.3415 34.4194 23.1694C35.5915 21.9973 36.25 20.4076 36.25 18.75C36.25 17.0924 35.5915 15.5027 34.4194 14.3306C33.2473 13.1585 31.6576 12.5 30 12.5H23.75C23.75 12.5 15.2344 12.5 7.04687 5.64061C6.86477 5.48991 6.64366 5.39394 6.40921 5.36382C6.17477 5.33371 5.93658 5.37069 5.7223 5.47047C5.50802 5.57025 5.32642 5.72874 5.19857 5.92756C5.07073 6.12637 5.00187 6.35737 5 6.59373V30.9062C5.00187 31.1426 5.07073 31.3736 5.19857 31.5724C5.32642 31.7712 5.50802 31.9297 5.7223 32.0295C5.93658 32.1293 6.17477 32.1663 6.40921 32.1361C6.64366 32.106 6.86477 32.01 7.04687 31.8594C15.2344 25 23.75 25 23.75 25H30Z" stroke={stroke} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
		</svg>
	);
};