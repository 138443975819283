// 3) Impresion e interaccion banners

// a) Impresion (pendiente por eventos del tipo impresion)
export const impresionBanners = (dataLayer, { idUsuario, nombreBanners }) => {
	dataLayer?.push?.({
		'event': 'cargar-banners',
		'user-id': idUsuario,
		'banners': nombreBanners,
		'ubicacion': 'Banners Home Persona'
	})
	return true;
}

// b) Deslizar propiedades (puntos miniatura y botones flecha)
export const deslizarBanners = (dataLayer, { idUsuario, nombreBanners }) => {
	dataLayer?.push?.({
		'event': 'deslizar-banners',
		'user-id': idUsuario,
		'banners': nombreBanners,
		'ubicacion': 'Banners Home Persona'
	})
}

// c) Clic banner (pendiente ya que ID proyecto no viene en json)
export const clicBanner = (dataLayer, { idUsuario, idProyecto, nombreBanner }) => {
	dataLayer?.push?.({
		'event': 'clic-banner',
		'user-id': idUsuario,
		'id-toctoc': idProyecto,
		'banner': nombreBanner,
		'ubicacion': 'Banners Home Persona'
	})
}