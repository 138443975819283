// 2) Interaccion buscador

// a) Busqueda normal
export const busquedaNormal = (dataLayer, { idUsuario, tipoPropiedad, tipoOperacion, nombreUbicacion, validacion }) => {
	dataLayer?.push?.({
		'event': 'busqueda',
		'user-id': idUsuario,
		'tipo-propiedad': tipoPropiedad,
		'operacion': tipoOperacion,
		'termino-busqueda': nombreUbicacion,
		'validacion': validacion 
	})
}

// b) Busqueda codigo
export const busquedaCodigo = (dataLayer, { idUsuario, idProyecto, validacion }) => {
	dataLayer?.push?.({
		'event': 'busqueda-codigo',
		'user-id': idUsuario,
		'id-toctoc': idProyecto,
		'validacion': validacion 
	})
};

// Kinesis 
export const seleccionBusquedaNormal = (dataLayer, { email, event, eventKey, eventValue, location, sessionId }) => {
	
	dataLayer?.push?.({
		location,
		email,
		event,
		eventKey,
		eventValue,
		sessionId
	})
}