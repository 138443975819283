import { Permissions } from "./enums"
import { SchemaPermission } from "./types.d";


export class AccessToctoc {
	private roleSplit: number[] = [];
	private rolePermitionSplit: string[] = [];
	private _schema: SchemaPermission;
	private section: string;
	constructor(schema: SchemaPermission = {}, section: string, roleSplit: number[]){
		this._schema = schema;
		this.roleSplit = roleSplit;
		this.section = section;
		this.rolePermitionSplit = Array.isArray(roleSplit) && this.roleSplit[3] ? this.roleSplit[3].toString().split("").reverse() : [];
	}
	private getPerfil(){
		if(this.roleSplit[1] === undefined || this.roleSplit[2] === undefined)
			return "";
		return `${this.roleSplit[1]}.${this.roleSplit[2]}`;
	}
	private findSectionIndex(){
		return this._schema?.[this.getPerfil()]?.sections?.findIndex(
			(section: string) => section === this.section
		) ?? -1;
	}

	canRead() {
		const index = this.findSectionIndex();
		if (index === -1) 
			return false;

		return [
			Permissions.READ + "",
			Permissions.READ_DELETE + "",
			Permissions.READ_WRITE + "",
			Permissions.READ_WRITE_DELETE + "",
		].includes(this.rolePermitionSplit?.[index] ?? "0");
	}
	canWrite() {
		const index = this.findSectionIndex();
		if (index === -1) 
			return false;
		
		return [
			Permissions.WRITE + "",
			Permissions.READ_WRITE + "",
			Permissions.WRITE_DELETE + "",
			Permissions.READ_WRITE_DELETE + "",
		].includes(this.rolePermitionSplit?.[index] ?? "0");
	}
	canDelete() {
		const index = this.findSectionIndex();
		if (index === -1) 
			return false;
		
		return [
			Permissions.DELETE + "",
			Permissions.READ_DELETE + "",
			Permissions.WRITE_DELETE + "",
			Permissions.READ_WRITE_DELETE + "",
		].includes(this.rolePermitionSplit?.[index] ?? "0");
	}

	can() {
		return {
			read: this.canRead(),
			write: this.canWrite(),
			delete: this.canDelete(),
		}
	}
}
