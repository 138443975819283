export const impresionProductosEmpresa = (dataLayer, { idUsuario=null, producto, categoria }) => {
	dataLayer?.push?.({
		'event': 'impresion-productos-empresa',
		'user-id': idUsuario,
		'producto': producto,
		'categoria': categoria
	});
	return true;
}

export const clickProductosEmpresa = (dataLayer, { idUsuario=null, producto, categoria }) => {
	dataLayer?.push?.({
		'event': 'click-productos-empresa',
		'user-id': idUsuario,
		'producto': producto,
		'categoria': categoria
	})
}