import React, { useCallback, useEffect, useRef } from "react";
import { setupEventListener } from '@toctoc/toctoc-event-tracker';

import {
	seleccionBusquedaNormal
} from './eventos';

const eventCaptureList = [
	{ event: 'click' },
];

const elementSelectorListHomePersonaClick = [
	{ callback: seleccionBusquedaNormal, selector: "#hp-btn-buscar-normal" },
	{ callback: seleccionBusquedaNormal, selector: "#selector-operation > *" },
	{ callback: seleccionBusquedaNormal, selector: "#selector-property > *" },

];

const elementSelectorList = [
	{
		type: "click",
		selectorPairList: [
			...elementSelectorListHomePersonaClick,
		]
	},
];

const arrayObserverHandler = (callback) =>({
	set(target, prop, value) {
		const LIMIT_ELEMENTS = 10;
		const CALLBACK_DELAY = 100;
		// console.log({ target, prop, value})
		if (target === "length") {
			return true;
		}
		target[prop] = value;
		// console.log(`property set: ${prop} =`, value);
		if (!isNaN(prop)) {
			const after = setTimeout(() => {
				callback(value);
				clearTimeout(after);
				if (target.length === 0)
					return;
				if (target.length > LIMIT_ELEMENTS) {
					// Clean to prevent a lot of entries in the local array
					target.splice(0, LIMIT_ELEMENTS);
				}
					
			}, CALLBACK_DELAY);
		}
		return true;
	},
});

export const KinesisEventos = ({eventCallback}) => {

	const sendEvent = useCallback((data) => {
		// console.info("Kinesis data", data);
		eventCallback(data)
		return;
	}, []);
	
	const dataLayerStateRef = useRef(new Proxy([], arrayObserverHandler(sendEvent)));
	
	const cleanupFn = useCallback((additionalCleanupFn) => () => {
		// should try to push all events first?
		// clearInterval(timer.current);
		dataLayerStateRef.current = []
		additionalCleanupFn();
	}, [])

	const setupEventFunction = useCallback(() => setupEventListener({
		window,
		baseObserver: () => document.querySelector("body"),
		// secondaryDOMElementList: () => document.querySelectorAll('.modal'),
		secondaryDOMElementList: () => [],
		dataLayer: dataLayerStateRef.current,
		elementSelectorList,
		eventCaptureList
	}), []);

	useEffect(() => {
		if (typeof window === "undefined")
			return undefined;
		const cleanupEventLib = setupEventFunction();
		return cleanupFn(cleanupEventLib)

	}, [setupEventFunction, cleanupFn, sendEvent]);

	return (<></>);
};