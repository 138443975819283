export const getCookie = (cookie) => {
	// TODO: Validar cookie
	
	let cookieSplit = cookie.split(';')

	let arrCookies = cookieSplit.map((coo) => {

		let cookieSinEspacios = coo.trim();
		let separarValue = cookieSinEspacios.split('=');
		let value = separarValue.pop();
		let name = separarValue.pop();

		return { name , value}
	})

	let arrfiltrado = arrCookies.find(({name}) => name === 'token');

	return arrfiltrado?.value;
}