// 4) Impresion e interaccion propiedades destacadas

// a) Impresion (pendiente por eventos del tipo impresion)
export const impresionCards = (dataLayer, { idUsuario }) => {
	dataLayer?.push?.({
		'event': 'cargar-propiedades-destacadas',
		'user-id': idUsuario,
		'ubicacion': 'Propiedades destacadas Home Persona'
	})
	return true;
}

// b) Deslizar propiedades (botones flecha) (pendiente ya que falta devolver valores)
export const deslizarPropiedades = (dataLayer, { idUsuario }) => {
	dataLayer?.push?.({
		'event': 'deslizar-propiedades-destacadas',
		'user-id': idUsuario,
		'ubicacion': 'Propiedades destacadas Home Persona'
	})
}

// c) Clic cambio tipo de propiedad
export const clicCambioDeTipoPropiedad = (dataLayer, { idUsuario, tipoPropiedad }) => {
	dataLayer?.push?.({
		'event': 'clic-cambio-tipo-de-propiedades-destacadas',
		'user-id': idUsuario,
		'tipo-propiedad': tipoPropiedad,
		'ubicacion': 'Propiedades destacadas Home Persona'
	})
}

// d) Clic propiedad destacada (validar forma de obtencion ID proyecto)
export const clicPropiedadDestacada = (dataLayer, { idUsuario, idProyecto, comuna, precio, cantidadDormitorios, cantidadBanos }) => {
	dataLayer?.push?.({
		'event': 'clic-propiedades-destacadas',
		'user-id': idUsuario,
		'id-toctoc': idProyecto,
		'comuna': comuna,
		'precio': precio,
		'dormitorios': cantidadDormitorios,
		'baños': cantidadBanos,
		'ubicacion': 'Propiedades destacadas Home Persona'
	})
}

// e) Favoritos
export const clicFavoritos = (dataLayer, { idUsuario, idProyecto, comuna, region, precio, cantidadDormitorios, cantidadBanos }) => {
	dataLayer?.push?.({
		'event': 'clic-favoritos',
		'user-id': idUsuario,
		'id-toctoc': idProyecto,
		'comuna': comuna,
		'region': region,
		'precio': precio,
		'dormitorios': cantidadDormitorios,
		'baños': cantidadBanos,
		'ubicacion': 'Propiedades destacadas Home Persona'
	})
}
