/* eslint-disable no-unused-vars */
/**
 * Represents the actions related to contracting.
 *Estas estan referenciadas por los bits a de [active].[external role].[internal role].[permisions].[internal api]
 * @example codigo completo 1.1.0.730003733071.0
 * @example permisos 730003733071
 * la lectura se realiza de derecha a izquierda
 * 
 * @example primer bit hace referencia a: Navegar/ navigation flag
 * @example segundo bit hace referencia a: Publicar/ publish flag
 * @example tercer bit hace referencia a: Cotizar/ quote flag
 * @example cuarto bit hace referencia a: Simular/ simulate flag
 * @example quinto bit hace referencia a: Pre-evaluar/ pre-evaluate flag
 * @example sexto bit hace referencia a: Buscar/ search flag
 * @example septimo bit hace referencia a: Pagar/ pay flag
 * @example octavo bit hace referencia a: Contratar/ contract flag
 * @example noveno bit hace referencia a: Productos corredora/ broker products flag
 * @example decimo bit hace referencia a: Favorito/ favorite flag
 * @example onceavo bit hace referencia a: Tasar/ appraise flag
 * @example doceavo bit hace referencia a: Reservar/ reserve flag
 * 
 */ 
export enum ACLActions {
	/*
	 * 
	 * 
	 */
	NAVEGAR = "navegar",

	/**
	 * Acciones de publicar.

	 * Para el caso de publicar, el bit segundo bit de permisos es 7
	 * @example [0] sin acceso a secciones
	 * @example [ver] Seccion publicar
	 * @example [ver] Seccion Mis publicaciones
	 * @example [Editar] Publicar una propiedad
	 * @example [Editar] Editar una propiedad
	 * @example [Eliminar] Eliminar una propiedad
	 */
	PUBLICAR = "publicar",

	/**
	 * Action for quoting.
	 */
	COTIZAR = "cotizaciones",

	/**
	 * Action for simulating.
	 */
	SIMULAR = "simular",

	/**
	 * Action for pre-evaluating.
	 */
	PREEVALUAR = "pre-evaluar",

	/**
	 * Action for searching.
	 */
	BUSCAR = "buscar",

	/**
	 * Action for paying.
	 */
	PAGAR = "pagar",

	/**
	 * Accion que hace referencia a contrar o desuscribir un plan de corredora.
	 * Para el caso de contratar, el bit octavo bit de permisos es 0
	 * 
	 * @example [0] sin acceso a secciones, seccion de mi plan
	 * @example [ver] Seccion Plan
	 * @example [Editar] Contratar un plan de corredora en Gestion corredor
	 * @example [Editar] Descargar contratos de 
	 * @example [Delete] Cancelar un plan de corredora en admin private altas
	 */
	CONTRATAR = "contratar",

	/**
	 * Action for accessing products as a broker.
	 */
	PRODUCTOS_CORREDORA = "productosCorredora",

	/**
	 * Action for marking as favorite.
	 */
	FAVORITO = "favorito",

	/**
	 * Action for appraising.
	 */
	TASAR = "tasar",

	/**
	 * Action for reserving.
	 */
	RESERVAR = "reservar",
}
