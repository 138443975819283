import { ACLActions } from "./aclAction"

const paths: Record<string, string> = {
	"2.0": "/admin/corredoras/",
	"4.0": "/admin/corredoras/",
	"8.0": "/admin/corredoras/",
	"16.0": "/admin/corredoras/",
	"512.0": "/admin/corredoras/"
}

// listado de opciones para comparar y filtrar
export const menuAliasACL: Record<string, { action: string, pathByRol?: Record<string, string>, roles?: string[] }> = {
	"Mi plan": {
		action: ACLActions.CONTRATAR,
		pathByRol: paths
	},
	"planes": {
		action: ACLActions.CONTRATAR,
		pathByRol: paths
	},
	"Mis publicaciones": {
		action: ACLActions.PUBLICAR,
		pathByRol: paths
	},
	"Solicitudes de contacto": {
		action: ACLActions.PUBLICAR,
		pathByRol: paths
	},
	"Informe Propiedad": {
		action: ACLActions.PUBLICAR
	},
	"Tu barrio": {
		action: ACLActions.PUBLICAR
	},
	"Mis favoritos": {
		action: ACLActions.PUBLICAR,
		roles: ["1.0"]
	},
	"Mis cotizaciones": {
		action: ACLActions.PUBLICAR,
		roles: ["1.0"]
	},
	"Configuración": {
		action: ACLActions.PUBLICAR,
		roles: ["1.0"]
	},
	"Ir a mi administrador": {
		action: ACLActions.PUBLICAR,
		roles: ["1.0"]
	},
}