import { ReactNode } from 'react';

import { loadFullHeaderStaticProps } from '@toctoc/design-system';
import { iLayoutProps } from './iProps';
import TocTocLayout from './layout';
import { UserProvider } from './UserContext';

export async function loadLayoutStaticProps(): Promise<Partial<iLayoutProps>> {
	const { setupServices } = await import('services.setup.server');
	const { getBannerHeader, urlBase, getDataFooter } = setupServices();

	const datosBannerHeader = await getBannerHeader();
	const footerData = await getDataFooter();

	const headerStaticProps = await loadFullHeaderStaticProps({
		fetch,
		baseUrl: process.env.URL_BASE_HEADER,
	});

	return {
		datosBannerHeader,
		headerStaticProps,
		urlBase,
		footerData,
	};
}

export function getPersonasLayout(page: ReactNode, layoutData: iLayoutProps) {

	return (
		<UserProvider>
			<TocTocLayout
				activeMenu="personas"
				canonicalBasePath="/"
				containerClassName="styles.container"
				idGTM={process.env.NEXT_PUBLIC_ID_GTM}
				title="TOCTOC – Departamentos y casas en arriendo y en venta"
				wootricDefaultSection="Home"
				{...layoutData}
			>
				{page}
			</TocTocLayout>
		</UserProvider>
	);
}

export function getEmpresasLayout(page: ReactNode, layoutData: iLayoutProps) {

	return (
		<UserProvider>
			<TocTocLayout
				activeMenu="empresas"
				canonicalBasePath="/empresas"
				containerClassName="styles.container"
				idGTM={process.env.NEXT_PUBLIC_EMPRESA_ID_GTM}
				title="TOCTOC – Empresas"
				wootricDefaultSection="Home Empresas"
				{...layoutData}
			>
				{page}
			</TocTocLayout>
		</UserProvider>
	);
}
