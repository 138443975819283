export const IcoBinoculares = ({ w = 40, h = 40, fill = "", stroke = '#3200C1', className = '' }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg"
			width={w}
			height={h}
			viewBox="0 0 40 40"
			className={className}
			fill="none">
			<path d="M16.25 14.375H23.75" stroke={stroke} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M35.875 24.1094L29.0469 8.59375C28.3419 7.89624 27.3902 7.505 26.3984 7.505C25.4067 7.505 24.455 7.89624 23.75 8.59375V26.25" stroke={stroke} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M16.25 26.25V8.59375C15.545 7.89624 14.5933 7.505 13.6016 7.505C12.6098 7.505 11.6581 7.89624 10.9531 8.59375L4.125 24.1094" stroke={stroke} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M10 32.5C13.4518 32.5 16.25 29.7018 16.25 26.25C16.25 22.7982 13.4518 20 10 20C6.54822 20 3.75 22.7982 3.75 26.25C3.75 29.7018 6.54822 32.5 10 32.5Z" stroke={stroke} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M30 32.5C33.4518 32.5 36.25 29.7018 36.25 26.25C36.25 22.7982 33.4518 20 30 20C26.5482 20 23.75 22.7982 23.75 26.25C23.75 29.7018 26.5482 32.5 30 32.5Z" stroke={stroke} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
		</svg>
	);
};