import { ACLActions } from "./aclAction";
import { SchemaPermission } from "./types";

export const getJsonSchemaRole = (): SchemaPermission => {
	const sections = [
		ACLActions.NAVEGAR
		, ACLActions.PUBLICAR
		, ACLActions.COTIZAR
		, ACLActions.SIMULAR
		, ACLActions.PREEVALUAR
		, ACLActions.BUSCAR
		, ACLActions.PAGAR
		, ACLActions.CONTRATAR
		, ACLActions.PRODUCTOS_CORREDORA
		, ACLActions.FAVORITO
		, ACLActions.TASAR
		, ACLActions.RESERVAR
	];

	const jsonRole: SchemaPermission = {
		"1.0": {
			name: "particular",
			sections: sections,
			otherSections: [],
		},
		"2.0": {
			name: "corredora matriz",
			sections: sections,
			otherSections: [],
		},
		"4.0": {
			name: "corredora",
			sections: sections,
			otherSections: [],
		},
		"8.0": {
			name: "corredora integrada",
			sections: sections,
			otherSections: [],
		},
		"16.0": {
			name: "corredor",
			sections: sections,
			otherSections: [],
		},
		"32.0": {
			name: "inmobiliaria",
			sections: sections,
			otherSections: [],
		},
		"64.0": {
			name: "ejecutivo inmobiliaria",
			sections: sections,
			otherSections: [],
		},
		"128.0": {
			name: "multifamily",
			sections: sections,
			otherSections: [],
		},
		"256.0": {
			name: "integradora",
			sections: sections,
			otherSections: [],
		},
		"512.0": {
			name: "Publicador Integrado",
			sections: sections,
			otherSections: [],
		}
	};

	return jsonRole;
};
