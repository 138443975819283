export const joinConjunction = (arr) => {
	if (arr === undefined || arr === null || arr.length === 0) return null
	const arrLength = arr.length
	if (arrLength === 1) return arr[0]
	let concatenated = ""
	arr.forEach((element, i) => {
		const isLatest = arrLength === i + 1
		const isFirst = 0 === i
		if (isFirst) {
			concatenated += element
		} else if (isLatest) {
			const firstWord = element.slice(0,1)
			const conE = firstWord.toLowerCase() === "i"
			if (conE) {
				concatenated += " e " + element
			} else {
				concatenated += " y " + element
			}
		} else {
			concatenated += ", " + element
		}
	});
	return concatenated
}