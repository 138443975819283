export const IcoCheckCircle = (props) => (
	<svg
		width={105}
		height={105}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<g filter="url(#a)">
			<circle cx={52.5} cy={50.5} r={48.5} fill="#BEE7D1" />
		</g>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M64.29 43.509a1.895 1.895 0 0 1-.062 2.678L50.327 59.45a1.894 1.894 0 0 1-2.617-.001l-6.939-6.631a1.894 1.894 0 1 1 2.618-2.74l5.63 5.382 12.593-12.013a1.895 1.895 0 0 1 2.679.063Z"
			fill="#343A40"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M52.5 29.66c-11.51 0-20.84 9.33-20.84 20.84s9.33 20.84 20.84 20.84 20.84-9.33 20.84-20.84-9.33-20.84-20.84-20.84ZM27.871 50.5c0-13.602 11.027-24.629 24.629-24.629 13.602 0 24.629 11.027 24.629 24.629 0 13.602-11.027 24.629-24.629 24.629-13.602 0-24.629-11.027-24.629-24.629Z"
			fill="#343A40"
		/>
		<defs>
			<filter
				id="a"
				x={0}
				y={0}
				width={105}
				height={105}
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy={2} />
				<feGaussianBlur stdDeviation={2} />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
				<feBlend
					in2="BackgroundImageFix"
					result="effect1_dropShadow_143_1191"
				/>
				<feBlend
					in="SourceGraphic"
					in2="effect1_dropShadow_143_1191"
					result="shape"
				/>
			</filter>
		</defs>
	</svg>
)

export default IcoCheckCircle
