export const IcoLocalComercial = ({ w = 64, h = 64, color = '#3200C1' }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={w} height={h} viewBox="0 0 64 64" fill="none">
			<path d="M12 34.9V52C12 52.5305 12.2107 53.0392 12.5858 53.4142C12.9609 53.7893 13.4696 54 14 54H50C50.5304 54 51.0391 53.7893 51.4142 53.4142C51.7893 53.0392 52 52.5305 52 52V34.9" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M13.5 10H50.5C50.9339 10.0036 51.3551 10.1464 51.7017 10.4075C52.0483 10.6685 52.3018 11.034 52.425 11.45L56 24H8L11.575 11.45C11.6982 11.034 11.9517 10.6685 12.2983 10.4075C12.6449 10.1464 13.0661 10.0036 13.5 10V10Z" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M24 24V28C24 30.1217 23.1571 32.1566 21.6569 33.6569C20.1566 35.1571 18.1217 36 16 36C13.8783 36 11.8434 35.1571 10.3431 33.6569C8.84285 32.1566 8 30.1217 8 28V24" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M40 24V28C40 30.1217 39.1571 32.1566 37.6569 33.6569C36.1566 35.1571 34.1217 36 32 36C29.8783 36 27.8434 35.1571 26.3431 33.6569C24.8429 32.1566 24 30.1217 24 28V24" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M56 24V28C56 30.1217 55.1571 32.1566 53.6569 33.6569C52.1566 35.1571 50.1217 36 48 36C45.8783 36 43.8434 35.1571 42.3431 33.6569C40.8429 32.1566 40 30.1217 40 28V24" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
		</svg>
	);
};