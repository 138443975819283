export const IcoCerrarMenu = ({ w = 25, h = 25, color = '#3200C1' }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg"
			width={w}
			height={h}
			viewBox="0 0 25 25"
			fill="none">
			<path d="M1 1L24 24M1 24L24 1" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
		</svg>
	);
};