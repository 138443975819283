export const IcoGps = ({ w = 12, h = 12, fill = "", color = '#666666' }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg"
			width={w}
			height={h}
			viewBox="0 0 12 12"
			fill="none">
			<path fillRule="evenodd" clipRule="evenodd" d="M6 3.75C5.37868 3.75 4.875 4.25368 4.875 4.875C4.875 5.49632 5.37868 6 6 6C6.62132 6 7.125 5.49632 7.125 4.875C7.125 4.25368 6.62132 3.75 6 3.75ZM4.125 4.875C4.125 3.83947 4.96447 3 6 3C7.03553 3 7.875 3.83947 7.875 4.875C7.875 5.91053 7.03553 6.75 6 6.75C4.96447 6.75 4.125 5.91053 4.125 4.875Z" fill={color} />
			<path fillRule="evenodd" clipRule="evenodd" d="M6 1.5C5.10489 1.5 4.24645 1.85558 3.61351 2.48851C2.98058 3.12145 2.625 3.97989 2.625 4.875C2.625 6.42748 3.49194 7.84112 4.40798 8.89456C4.86141 9.41601 5.31581 9.83643 5.65693 10.1264C5.79183 10.2411 5.90859 10.3349 6 10.4062C6.09141 10.3349 6.20817 10.2411 6.34307 10.1264C6.68419 9.83643 7.13859 9.41601 7.59202 8.89456C8.50806 7.84112 9.375 6.42748 9.375 4.875C9.375 3.97989 9.01942 3.12145 8.38649 2.48851C7.75355 1.85558 6.89511 1.5 6 1.5ZM6 10.875C5.78495 11.1822 5.78485 11.1821 5.78473 11.1821L5.78442 11.1818L5.78354 11.1812L5.78077 11.1793L5.77123 11.1725C5.76313 11.1667 5.75157 11.1584 5.73678 11.1476C5.70721 11.126 5.66472 11.0945 5.61115 11.0536C5.50405 10.9717 5.35246 10.8519 5.17119 10.6978C4.80919 10.3901 4.32609 9.94337 3.84202 9.38669C2.88306 8.28388 1.875 6.69752 1.875 4.875C1.875 3.78098 2.3096 2.73177 3.08318 1.95818C3.85677 1.1846 4.90598 0.75 6 0.75C7.09402 0.75 8.14323 1.1846 8.91682 1.95818C9.6904 2.73177 10.125 3.78098 10.125 4.875C10.125 6.69752 9.11694 8.28388 8.15798 9.38669C7.67391 9.94337 7.19081 10.3901 6.82881 10.6978C6.64754 10.8519 6.49595 10.9717 6.38885 11.0536C6.33528 11.0945 6.29279 11.126 6.26322 11.1476C6.24843 11.1584 6.23687 11.1667 6.22877 11.1725L6.21923 11.1793L6.21646 11.1812L6.21558 11.1818L6.21527 11.1821C6.21515 11.1821 6.21505 11.1822 6 10.875ZM6 10.875L6.21505 11.1822C6.08593 11.2726 5.91407 11.2726 5.78495 11.1822L6 10.875Z" fill={color} />
		</svg>
	);
};