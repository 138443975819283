export const IcoMejoramos = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="40"
			height="40"
			fill="none"
			viewBox="0 0 40 40"
		>
			<g fill="#3200C1" clipPath="url(#clip0_83_596)">
				<path
					fillRule="evenodd"
					d="M9.216 3.915L4.02 8.501v5.8h3.082V11.3c0-.285.116-.555.319-.751.201-.196.471-.303.75-.303h2.088c.278 0 .548.107.75.303.202.196.319.466.319.75v3.003h3.082v-5.8L9.216 3.915zM8.5 3.087a1.078 1.078 0 011.43 0l5.22 4.607a1.073 1.073 0 01.355.775l-.001.01v5.862c0 .285-.117.555-.319.75a1.077 1.077 0 01-.75.304h-3.132c-.279 0-.549-.107-.75-.303a1.047 1.047 0 01-.319-.75v-3.003H8.197v3.002c0 .285-.117.555-.32.75a1.077 1.077 0 01-.75.304H3.997c-.279 0-.549-.107-.75-.303a1.047 1.047 0 01-.32-.75V8.477 8.47a1.06 1.06 0 01.355-.775l5.22-4.606z"
					clipRule="evenodd"
				></path>
				<path
					fillRule="evenodd"
					d="M8.408 2.982a1.218 1.218 0 011.616 0l5.22 4.607.01.009a1.214 1.214 0 01.392.869m0 0V14.34c0 .324-.133.63-.362.852a1.218 1.218 0 01-.848.343h-3.132c-.314 0-.62-.121-.848-.343a1.187 1.187 0 01-.362-.852V11.48H8.338v2.861c0 .324-.133.63-.362.852a1.218 1.218 0 01-.848.343H3.996c-.315 0-.62-.121-.848-.343a1.188 1.188 0 01-.362-.852V8.467a1.2 1.2 0 01.393-.87l.01-.008 5.218-4.606v-.001m.809-.024a.937.937 0 00-.621.235h-.001L3.374 7.8l-.005.005a.932.932 0 00-.301.667V14.34c0 .246.1.48.276.65.175.17.41.263.652.263h3.132a.936.936 0 00.652-.263.906.906 0 00.276-.65v-3.002a.14.14 0 01.14-.141h2.04a.14.14 0 01.14.14v3.003c0 .246.1.48.276.65.175.17.41.263.652.263h3.132a.936.936 0 00.652-.263.906.906 0 00.276-.65V8.472a.92.92 0 00-.301-.667m0 0l-.006-.005-5.219-4.606v-.001m-.715.617a.14.14 0 01.186 0l5.196 4.585a.14.14 0 01.047.106v5.8a.14.14 0 01-.14.141h-3.083a.14.14 0 01-.14-.14v-3.003c0-.246-.102-.48-.277-.65a.936.936 0 00-.652-.263H8.172a.936.936 0 00-.652.263.906.906 0 00-.276.65V14.3a.14.14 0 01-.14.141H4.02a.14.14 0 01-.141-.14V8.5a.14.14 0 01.048-.106L9.123 3.81zM4.162 8.564v5.596h2.8V11.3c0-.324.133-.63.362-.852.228-.222.534-.343.848-.343h2.088c.315 0 .62.121.848.343.23.222.362.528.362.852v2.861h2.8V8.564L9.216 4.103 4.162 8.564z"
					clipRule="evenodd"
				></path>
				<path d="M9.336 17.334a4.666 4.666 0 100 9.331 4.666 4.666 0 000-9.331zM6.003 22a3.339 3.339 0 012.666-3.266v2.888L6.203 23.1a3.286 3.286 0 01-.2-1.101zm.887 2.244l2.342-1.406 1.939 1.938a3.3 3.3 0 01-4.281-.532zm5.223-.41l-2.11-2.11v-2.99a3.327 3.327 0 012.11 5.1zM35.997 12.002V9.72c0-.58-.471-1.05-1.051-1.051h-1.897c-.58 0-1.051.47-1.052 1.051v2.282h-1.333v-4.18c0-.636-.516-1.152-1.153-1.153h-1.693c-.636.001-1.152.517-1.153 1.154v4.179h-1.333V4.67c0-.736-.597-1.333-1.333-1.333h-1.333c-.736 0-1.333.597-1.333 1.333v7.332h-.666a.667.667 0 01-.667-.667V3.337a.667.667 0 00-1.333 0v7.998a2 2 0 002 2h15.33a.666.666 0 100-1.333zm-11.998 0h-1.333V4.67H24v7.332zm5.332 0h-1.333v-4h1.333v4zm5.333 0H33.33v-2h1.333v2zM35.997 25.332h-15.33a.666.666 0 01-.667-.666v-1.057l2.195-2.195a.68.68 0 01.942 0l.782.78a2 2 0 002.827 0l2.78-2.78a.68.68 0 01.943 0l.781.782a2.003 2.003 0 002.828 0l2.39-2.39a.666.666 0 00-.943-.943l-2.39 2.39a.667.667 0 01-.942 0l-.781-.781a2.048 2.048 0 00-2.828 0l-2.78 2.78a.667.667 0 01-.943 0l-.78-.78a2.048 2.048 0 00-2.829 0L20 21.723v-5.057a.667.667 0 00-1.333 0v7.999a2 2 0 002 2h15.33a.666.666 0 100-1.334z"></path>
				<path d="M39.996 30.967V3.7A3.533 3.533 0 0036.663.004H3.337A3.533 3.533 0 00.004 3.7v27.267a3.533 3.533 0 003.333 3.697H14v1.333h-2.666a2.666 2.666 0 00-2.666 2.666v.666c0 .368.298.667.667.667h21.329a.666.666 0 00.666-.667v-.666a2.666 2.666 0 00-2.666-2.666h-2.666v-1.333h10.664a3.533 3.533 0 003.333-3.697zM28.665 37.33c.736 0 1.333.597 1.333 1.333H10.002c0-.736.597-1.333 1.333-1.333h17.33zm-13.33-1.333v-1.333h9.33v1.333h-9.33zm21.328-2.666H3.337a2.208 2.208 0 01-2-2.364v-1.636h37.326v1.636a2.208 2.208 0 01-2 2.364zm2-29.631v24.298H1.337V3.7a2.207 2.207 0 012-2.363h33.326c1.2.11 2.09 1.162 2 2.363z"></path>
			</g>
			<defs>
				<clipPath id="clip0_83_596">
					<path fill="#fff" d="M0 0H40V40H0z"></path>
				</clipPath>
			</defs>
		</svg>
	);
}

export default IcoMejoramos;