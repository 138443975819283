export const impresionBannerCorredora = (dataLayer, { idUsuario=null, banner, apartado }) => {
	dataLayer?.push?.({
		'event': 'banners',
		'user-id': idUsuario,
		'banner': banner,
		'apartado': apartado
	});
	return true;
}

export const clickBannerCorredora = (dataLayer, { idUsuario=null, banner, apartado }) => {
	dataLayer?.push?.({
		'event': 'click-banners',
		'user-id': idUsuario,
		'banner': banner,
		'apartado': apartado
	})
}