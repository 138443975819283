export const IcoIndustrial = ({ w = 64, h = 64, color = '#3200C1' }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={w} height={h} viewBox="0 0 64 64" fill="none">
			<path d="M20 44H27" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M37 44H44" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M54 54V34H42L26 22V34L10 22V54" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M4 54H60" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M54 34L50.25 7.725C50.1838 7.24781 49.9475 6.81057 49.5847 6.49366C49.2218 6.17674 48.7568 6.00145 48.275 6H43.725C43.2432 6.00145 42.7782 6.17674 42.4153 6.49366C42.0525 6.81057 41.8162 7.24781 41.75 7.725L38.375 31.3" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
		</svg>
	);
};