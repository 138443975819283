export const IcoCerrar = ({ w = 8, h = 8, fill = "", color = '#3200C1' }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg"
			width={w}
			height={h}
			viewBox="0 0 8 8"
			fill="none">
			<path d="M0.666992 0.666504L7.33366 7.33317M0.666992 7.33317L7.33366 0.666504" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
		</svg>
	);
};