export const IcoChevron2 = ({ w = 6, h = 11, fill = "", color = '#0645AD' }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg"
			width={w}
			height={h}
			viewBox="0 0 6 11"
			className="chevron2"
			fill="none">
			<path d="M5.33205 5.29259C5.32281 5.16044 5.27052 5.03526 5.18353 4.93705L1.01076 0.19647C0.872301 0.0389854 
					0.662183 -0.0310014 0.459568 0.0128763C0.256952 0.0567541 0.0926264 0.207829 0.028498 0.409186C-0.0356305 
					0.610542 0.010183 0.831584 0.148679 0.989036L3.97372 5.33334L0.14868 9.67763C0.0101846 9.83508 -0.0356288 
					10.0561 0.0284997 10.2575C0.0926282 10.4588 0.256954 10.6099 0.45957 10.6538C0.662185 10.6977 0.872303 10.6277 
					1.01076 10.4702L5.18353 5.72961C5.2889 5.61053 5.3425 5.45282 5.33205 5.29259Z" fill={color} />
		</svg>
	);
};