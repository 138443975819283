export const IcoEstacionamiento = ({ w = 64, h = 64, color = '#3200C1' }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={w} height={h} viewBox="0 0 64 64" fill="none">
			<path d="M4 31.5862H60" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M56 48.1379V54.3448C56 54.8936 55.7893 55.4198 55.4142 55.8078C55.0391 56.1958 54.5304 56.4138 54 56.4138H48C47.4696 56.4138 46.9609 56.1958 46.5858 55.8078C46.2107 55.4198 46 54.8936 46 54.3448V48.1379" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M18 48.1379V54.3448C18 54.8936 17.7893 55.4198 17.4142 55.8078C17.0391 56.1958 16.5304 56.4138 16 56.4138H10C9.46957 56.4138 8.96086 56.1958 8.58579 55.8078C8.21071 55.4198 8 54.8936 8 54.3448V48.1379" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M16 39.8621H20" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M44 39.8621H48" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M56 31.5863L48.525 14.207C48.3699 13.8393 48.1148 13.5262 47.7908 13.3058C47.4668 13.0854 47.0878 12.9672 46.7 12.9656H17.3C16.9122 12.9672 16.5332 13.0854 16.2092 13.3058C15.8852 13.5262 15.6301 13.8393 15.475 14.207L8 31.5863V48.138H56V31.5863Z" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
		</svg>
	);
};