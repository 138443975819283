export const impresionCargaEmpresas = (dataLayer, { idUsuario=null, categoria }) => {
	dataLayer?.push?.({
		'event': 'carga-empresas',
		'user-id': idUsuario,
		'categoria': categoria
	});
	return true;
}

export const clickTipoPropiedad = (dataLayer, { idUsuario=null, categoria }) => {
	dataLayer?.push?.({
		'event': 'click-cambio-categoria-empresas',
		'user-id': idUsuario,
		'categoria': categoria
	})
}

export const clickVerMasEmpresas = (dataLayer, { idUsuario=null, categoria }) => {
	dataLayer?.push?.({
		'event': 'click-ver-mas-empresas',
		'user-id': idUsuario,
		'categoria': categoria
	})
}