import { useState, useEffect, Dispatch, SetStateAction, useCallback } from 'react';

const isNullOrEmpty = (s: string) => (s === null || s === undefined || s.trim() === '');
const tryParseValue = (str: string) => {
	try {
		return { ok: true, output: JSON.parse(str) };

	} catch {
		return { ok: false, output: null };
	}
};

// eslint-disable-next-line no-undef
export interface UseStorageWindowEventMap extends WindowEventMap {
	'session': CustomEvent,
	'session-storage': CustomEvent,
	'local-storage': CustomEvent

}

// useState<S>(initialState: S | (() => S)): ;
export function useStorage<T>(key: string, initialValue: T, storageImplementation: Storage, useStorageEventType: keyof UseStorageWindowEventMap): [T, Dispatch<SetStateAction<T>>] {

	const browserHasLocalStorageAvailable = typeof window !== 'undefined' && storageImplementation !== undefined;
	const storage = browserHasLocalStorageAvailable ? storageImplementation : null;

	const getStorageValue = useCallback(() => {
		if (!browserHasLocalStorageAvailable)
			return initialValue;
		if (isNullOrEmpty(key))
			return initialValue;
		const storageValue = storage.getItem(key);
		if (isNullOrEmpty(storageValue))
			return initialValue;
		const parsedValue = tryParseValue(storageValue);
		const valueToSet = parsedValue.ok ? parsedValue.output : storageValue

		return valueToSet;
	}, [browserHasLocalStorageAvailable, initialValue, key, storage])

	const [value, setValue] = useState(getStorageValue());

	const setValueWraper: Dispatch<SetStateAction<T>> = useCallback((_value) => {
		if (!browserHasLocalStorageAvailable)
			return;

		if (_value === null || _value === undefined) {
			storage.removeItem(key);
			setValue(_value)
			window.dispatchEvent(new Event(useStorageEventType))
			return;
		}

		const requireSerialization = typeof _value === typeof {} || typeof _value === typeof [];
		const serializedValue = requireSerialization ? JSON.stringify(_value) : null;

		const valueToSet = requireSerialization ? serializedValue : '' + _value

		storage.setItem(key, valueToSet);
		setValue(_value)
		window.dispatchEvent(new Event(useStorageEventType))

	}, [browserHasLocalStorageAvailable, storage, key, useStorageEventType])

	useEffect(() => {
		setValue(getStorageValue())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const storageListenerCallback = useCallback((ev) => {

		const { key: storageKey } = ev
		if (storageKey !== undefined && storageKey !== null && storageKey !== key) return

		setValue(getStorageValue())
	}, [getStorageValue, key])

	const setupListenerEvent = useCallback((eventName: keyof UseStorageWindowEventMap) => {
		window.addEventListener(eventName, storageListenerCallback)
		return () => window.removeEventListener(eventName, storageListenerCallback)
	}, [storageListenerCallback])

	useEffect(() => setupListenerEvent("session"), [setupListenerEvent])
	useEffect(() => setupListenerEvent(useStorageEventType), [setupListenerEvent, useStorageEventType])

	return [value, setValueWraper];
}