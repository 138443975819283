/**
 * 
 * @param {ServiceContextAPIProvider} context 
 * @param {string} endpoint 
 * @param {{get:() => ()=> Promise, post:() => ()=> Promise, put:() => ()=> Promise, delete:() => () => Promise}}} param2 
 * @returns 
 */
export const restWrapper = (context, endpoint, { get, post, put, delete: deleteEndpoint }) => {
	const { baseUrl } = context;
	const endpointContext = { ...context, endpoint: `${baseUrl}${endpoint}` };
	const notImplementedError = () => { throw Error("Not implemented"); };
	/**
	 * 
	 * @param {*} method 
	 * @returns {()=>Promise}
	 */
	const setEndpoint = (method) => (method ? method(endpointContext) : notImplementedError);

	return {
		get: setEndpoint(get),
		post: setEndpoint(post),
		put: setEndpoint(put),
		delete: setEndpoint(deleteEndpoint),
	};
};

/**
 * 
 * @param {Object} param0 
 * @param {string} param0.endpoint API endpoint
 * @param {number} param0.id Potential model id to look for (exclude query parameters)
 * @param {Array<string>} param0.inputQueryParameters Query string parameter list (key=value)
 * @param {Object} param0.services Specify how to do service common operation, network request, log record
 * @param {LoggerService} param0.services.logger
 * @param {FetchService} param0.services.fetch 
 * @returns 
 */
export const requestWrapper = async ({
	endpoint,
	id,
	inputQueryParameters,
	services: { logger, fetch }
}) => {
	try {
		const queryParameters = id ? `/${id}` :
			"?" + inputQueryParameters.filter(e => e).join("&") ?? "";

		const requestUrl = `${endpoint}${queryParameters}`;

		logger && logger.addBreadcrumb({ message: requestUrl });
		const request = await fetch(requestUrl);


		logger && logger.addBreadcrumb(
			{
				message: request.statusText,
				data: {
					requestStatus: request.status,
					requestUrl: request.url,
					isOK: request.ok,
					// requestText: await request.clone().text()
				}
			});

		return request.json();
	}
	catch (ex) {

		logger && logger.captureException(ex);
		return null;
	}
};

export const getArrayQueryParameter = (name, value) => {
	if (name === null || name === undefined)
		return null;
	if (value === null || value === undefined)
		return null;
	if (value.length === 0)
		return null;

	return `${name}=${value.join(",")}`;

};

export const getSingleQueryParameter = (name, value) => {
	if (name === null || name === undefined)
		return null;
	if (value === null || value === undefined)
		return null;

	return `${name}=${value}`;

};