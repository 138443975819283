import { asyncServiceLoader } from "modules";
import { useSession } from "modules/hooks";
import React, { useContext, createContext } from "react";

export const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
	
	const [sessionState, setSessionState, sessionSetup] = useSession({
		sessionServices: {
			getCookieList: asyncServiceLoader('getCookieList'),
			getServerDomain: asyncServiceLoader('getServerDomain'),
			validateToken: asyncServiceLoader('validateToken'),
			getUserInfoByToken: asyncServiceLoader('getUserInfoByToken'),
			getInformationUser: asyncServiceLoader('getInformationUser'),
		},
	});

	return (
		<UserContext.Provider value={{ sessionState, setSessionState, sessionSetup }}>
			{children}
		</UserContext.Provider>
	)
}