export const IcoFacilitamos = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="40"
			height="40"
			fill="none"
			viewBox="0 0 40 40"
		>
			<g fill="#3200C1" stroke="#3200C1" clipPath="url(#clip0_83_571)">
				<path
					strokeWidth="0.2"
					d="M28.32 24.551a.667.667 0 000 .943l5.546 5.545a.664.664 0 00.943 0 .667.667 0 000-.943l-5.545-5.545a.668.668 0 00-.944 0zM37.333 14.944v6.39h-2.666v-3.333a.666.666 0 10-1.334 0v4c0 .368.298.667.667.667h4a.667.667 0 00.666-.667v-5.724l.196.195a.665.665 0 00.942 0 .666.666 0 000-.942l-1.332-1.333-.002-.001-5.999-6a.666.666 0 10-.942.943l5.804 5.805zM16 2.668C8.648 2.668 2.667 8.649 2.667 16S8.648 29.334 16 29.334s13.333-5.981 13.333-13.333S23.352 2.668 16 2.668zM16 28c-6.617 0-12-5.383-12-12s5.383-12 12-12 12 5.383 12 12-5.383 12-12 12z"
				></path>
				<path
					strokeWidth="0.2"
					d="M34.351 29.639a.666.666 0 00-.471-.195h0a.672.672 0 00-.473.196l-.022.018c-.225.176-.317.413-.094.592l.03.023c.26.26.195.43.56.782l4.2 4.199c.377.378.585.88.585 1.414 0 .534-.208 1.036-.585 1.414-.78.779-2.048.78-2.829 0l-9.778-9.779a.667.667 0 00-.943.943l9.779 9.779c.65.65 1.502.974 2.356.974.853 0 1.708-.325 2.357-.974.63-.63.977-1.467.977-2.358 0-.89-.347-1.727-.977-2.356l-4.672-4.672z"
				></path>
				<path
					strokeWidth="0.2"
					d="M32 16.001c0-8.822-7.178-16-16-16s-16 7.178-16 16 7.178 16 16 16 16-7.178 16-16zM16 30.668c-8.087 0-14.667-6.58-14.667-14.667S7.913 1.335 16 1.335 30.666 7.914 30.666 16c0 8.088-6.579 14.667-14.666 14.667z"
				></path>
				<path
					fillRule="evenodd"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="0.5"
					d="M15.976 10.16l-5.195 4.586v5.8h3.083v-3.002c0-.284.116-.554.318-.75.202-.196.472-.303.75-.303h2.089c.278 0 .548.107.75.303.202.196.319.466.319.75v3.003h3.082v-5.8l-5.196-4.586zm-.714-.827a1.077 1.077 0 011.43 0s0 0 0 0l5.219 4.607.008.007a1.073 1.073 0 01.347.777v5.863c0 .284-.117.554-.32.75a1.076 1.076 0 01-.75.303h-3.131c-.279 0-.549-.107-.75-.303a1.047 1.047 0 01-.32-.75v-3.003h-2.038v3.003c0 .284-.116.554-.318.75a1.076 1.076 0 01-.75.303h-3.133c-.278 0-.548-.107-.75-.303a1.047 1.047 0 01-.319-.75v-5.863-.01a1.06 1.06 0 01.347-.767l.008-.007 5.219-4.607s0 0 0 0z"
					clipRule="evenodd"
				></path>
			</g>
			<defs>
				<clipPath id="clip0_83_571">
					<path fill="#fff" d="M0 0H40V40H0z"></path>
				</clipPath>
			</defs>
		</svg>
	);
}
export default IcoFacilitamos;