export const IcoBurbujaChat = ({ w = 40, h = 40, fill = "", stroke = '#3200C1', className = '', dotsColor = '#3200C1' }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg"
			width={w}
			height={h}
			viewBox="0 0 40 40"
			className={className}
			fill="none">
			<path d="M7.09375 27.6562C5.23263 24.5163 4.58164 20.8049 5.26298 17.219C5.94432 13.633 7.91113 10.4191 10.7942 8.18051C13.6772 5.94193 17.2783 4.83267 20.9212 5.06097C24.5642 5.28928 27.9985 6.83944 30.5795 9.42046C33.1606 12.0015 34.7107 15.4358 34.939 19.0788C35.1673 22.7217 34.0581 26.3228 31.8195 29.2058C29.5809 32.0889 26.367 34.0557 22.781 34.737C19.1951 35.4184 15.4837 34.7674 12.3437 32.9062L7.15625 34.375C6.94371 34.4372 6.71837 34.441 6.50384 34.3861C6.28931 34.3312 6.09349 34.2197 5.93691 34.0631C5.78032 33.9065 5.66875 33.7107 5.61387 33.4962C5.55899 33.2816 5.56284 33.0563 5.625 32.8437L7.09375 27.6562Z" stroke={stroke} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M20 21.875C21.0355 21.875 21.875 21.0355 21.875 20C21.875 18.9645 21.0355 18.125 20 18.125C18.9645 18.125 18.125 18.9645 18.125 20C18.125 21.0355 18.9645 21.875 20 21.875Z" fill={dotsColor} />
			<path d="M12.5 21.875C13.5355 21.875 14.375 21.0355 14.375 20C14.375 18.9645 13.5355 18.125 12.5 18.125C11.4645 18.125 10.625 18.9645 10.625 20C10.625 21.0355 11.4645 21.875 12.5 21.875Z" fill={dotsColor} />
			<path d="M27.5 21.875C28.5355 21.875 29.375 21.0355 29.375 20C29.375 18.9645 28.5355 18.125 27.5 18.125C26.4645 18.125 25.625 18.9645 25.625 20C25.625 21.0355 26.4645 21.875 27.5 21.875Z" fill={dotsColor} />
		</svg>
	);
};