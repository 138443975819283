export const IcoCheck = ({ w = 16, h = 16, fill = "", stroke = '#F05C89' }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg"
			width={w}
			height={h}
			viewBox="0 0 16 16"
			fill="none">
			<path d="M13.3333 4L5.99999 11.3333L2.66666 8" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
		</svg>
	);
};